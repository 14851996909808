//Clerk
import { UserButton } from "@clerk/clerk-react";

export function CustomUserButton() {
  return (
    <>
      <UserButton
        appearance={{
          elements: {
            avatarBox: {
              width: "2.5rem",
              height: "2.5rem",
              boxShadow: "0 0.2rem 0.5rem #444444bb", // Add box shadow
            },
            userButtonOuter: {
              width: "2.5rem",
              height: "2.5rem",
              boxShadow: "0 0.2rem 0.5rem #444444bb", // Add box shadow
            },
          },
        }}
      />
    </>
  );
}

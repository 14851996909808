// The URL to your endpoint
const baseURL = "https://erudite-imprint-406809.ey.r.appspot.com";

export async function fetchServerData(endpoint) {
  try {
    const response = await fetch(baseURL + endpoint);

    // Check if the response is ok (status in the range 200-299)
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parses the JSON response body
    const data = await response.json();

    // `data` is the parsed JSON object from the server
    return data; // Return the data for further processing
  } catch (error) {
    // Handle any errors
    console.error("There has been a problem with your fetch operation:", error);
    throw error; // Rethrow the error if you want to handle it where the function is called
  }
}

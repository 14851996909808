import Ajv from "ajv";
import { filtersSchema } from "../data/schemas/filterSchema";

const ajv = new Ajv({ allErrors: true, useDefaults: true });
const validate = ajv.compile(filtersSchema);

export const mergeFiltersWithDefaults = (loadedFilters) => {
  try {
    let parsedFilters = loadedFilters ? JSON.parse(loadedFilters) : {};

    if (!validate(parsedFilters)) {
      console.warn(
        "No valid filters loaded or some filter values are invalid. Using default filter settings:",
        validate.errors
      );
      parsedFilters = applyDefaults(filtersSchema.properties);
    }

    return parsedFilters;
  } catch (error) {
    console.error("Error parsing filters:", error);

    return applyDefaults(filtersSchema.properties);
  }
};

function applyDefaults(schemaProperties) {
  return Object.keys(schemaProperties).reduce((obj, key) => {
    if (
      schemaProperties[key].type === "object" &&
      schemaProperties[key].properties
    ) {
      // For nested objects like 'colors', apply defaults recursively
      obj[key] = applyDefaults(schemaProperties[key].properties);
    } else {
      // For other properties, use the default if it exists
      obj[key] = schemaProperties[key].hasOwnProperty("default")
        ? schemaProperties[key].default
        : {};
    }
    return obj;
  }, {});
}

import { useMemo, memo } from "react";
import ReactApexChart from "react-apexcharts";

/**
 * Maps a given week number to a month name using JavaScript's Date object.
 *
 * @param {number} weekNum - The week number (e.g., 1-53).
 * @param {number} year - The year (e.g., 2024).
 * @returns {string} - The month and year string (e.g., 'Jan 2024').
 */
const mapWeekToMonth = (weekNum, year) => {
  // Calculate the date corresponding to the week number
  const firstDayOfYear = new Date(year, 0, 1);
  const daysOffset = (weekNum - 1) * 7;
  const weekDate = new Date(
    firstDayOfYear.getTime() + daysOffset * 24 * 60 * 60 * 1000
  );

  // Get the month name
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  const month = monthNames[weekDate.getMonth()];

  return `${month} ${year}`;
};

/**
 * Processes the route price data into a format suitable for ApexCharts.
 *
 * @param {Object} routeData - The price data for the route, organized by carrier.
 * @returns {{ series: Array, categories: Array, monthCategories: Array }} - The processed series and categories for the chart.
 */
const processPriceDataForApexCharts = (routeData) => {
  if (!routeData) return { series: [], categories: [], monthCategories: [] };

  // Collect all weeks from the data to ensure consistent x-axis categories.
  const allWeeksSet = new Set();
  Object.values(routeData).forEach((carrierData) => {
    carrierData.forEach(([week]) => {
      allWeeksSet.add(week);
    });
  });

  const allWeeks = Array.from(allWeeksSet).sort((a, b) => a - b);

  // Format the week numbers into readable categories for the x-axis.
  const categories = allWeeks.map((week) => {
    const weekStr = week.toString();
    const year = weekStr.substring(0, 4);
    const weekNum = weekStr.substring(4).padStart(2, "0");
    return `W${weekNum} ${year}`;
  });

  // Map weeks to months for x-axis labels
  const monthCategories = allWeeks.map((week) => {
    const weekStr = week.toString();
    const year = parseInt(weekStr.substring(0, 4), 10);
    const weekNum = parseInt(weekStr.substring(4), 10);

    return mapWeekToMonth(weekNum, year);
  });

  // Build series data
  const series = Object.entries(routeData)
    .map(([carrierName, prices]) => {
      if (!prices || prices.length === 0) return null; // Exclude carriers with no data

      const priceMap = new Map(
        prices.map(([week, minPrice, avgPrice]) => [week, avgPrice])
      );

      // Map the prices to the corresponding weeks, filling gaps with null.
      const data = allWeeks.map((week) => priceMap.get(week) ?? null);

      return {
        name: carrierName,
        data,
      };
    })
    .filter(Boolean); // Remove null entries (carriers with no data)

  return { series, categories, monthCategories };
};

/**
 * Generates the chart options for ApexCharts.
 *
 * @param {Array} categories - The original categories (week labels).
 * @param {Array} monthCategories - The month labels corresponding to each week.
 * @returns {Object} - The configuration options for the chart.
 */
const generateChartOptions = (categories, monthCategories) => {
  // Determine the indices of labels to display: first, last, and evenly spaced in between.
  const totalLabels = monthCategories.length;
  const maxLabelsToShow = 8; // First, last, and up to 6 in between.
  let indicesToShow = [];

  if (totalLabels <= maxLabelsToShow) {
    // If total labels are less than or equal to maxLabelsToShow, show all labels.
    indicesToShow = Array.from({ length: totalLabels }, (_, i) => i);
  } else {
    // Always include the first and last indices.
    indicesToShow = [0, totalLabels - 1];

    // Calculate evenly spaced indices for the remaining labels.
    const intervals = maxLabelsToShow - 2; // Subtracting first and last labels.
    const step = totalLabels / (intervals + 1);

    for (let i = 1; i <= intervals; i++) {
      const index = Math.round(i * step);
      if (index > 0 && index < totalLabels - 1) {
        indicesToShow.push(index);
      }
    }

    // Remove duplicates and sort indices
    indicesToShow = Array.from(new Set(indicesToShow)).sort((a, b) => a - b);
  }

  // Create displayCategories with labels only at indicesToShow, empty strings elsewhere.
  const displayCategories = monthCategories.map((label, index) =>
    indicesToShow.includes(index) ? label : ""
  );

  return {
    chart: {
      type: "line",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: [
      "#0B486C", // Slate-NEA
      "#20BF55", // Green-NEA
      "#058aff",
      "#dc9e03",
      "#7d00ea",
      "#ff6600",
      "#01BAEF", // Blue-NEA
      "#008552",
    ],
    dataLabels: { enabled: false },
    stroke: {
      curve: "straight",
      lineCap: "square",
      width: 2,
      connectNullData: false, // Do not connect lines across null data
    },
    xaxis: {
      categories: displayCategories,
      tooltip: {
        enabled: false,
      },
      labels: {
        rotate: 0,
      },
    },
    yaxis: {
      title: { text: "Average Price (USD)" },
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      y: {
        formatter: (value) =>
          value !== null && value !== undefined ? `$${value}` : null,
      },
      x: {
        formatter: (value, { dataPointIndex }) => {
          // Use the original categories array for tooltips to show the full week label
          return categories[dataPointIndex];
        },
      },
    },
    legend: { position: "top" },
  };
};

/**
 * PriceChart component renders the price chart for a given route.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.data - The price data to be visualized in the chart.
 * @returns {JSX.Element} - The rendered chart component.
 */
const PriceChart = ({ data, id }) => {
  // Memoize the processed data to avoid unnecessary recalculations.
  const { categories, series, monthCategories } = useMemo(
    () => processPriceDataForApexCharts(data),
    [data]
  );

  // Generate the chart options with the categories.
  const chartOptions = useMemo(
    () => generateChartOptions(categories, monthCategories),
    [categories, monthCategories]
  );

  return (
    <ReactApexChart
      options={chartOptions}
      series={series}
      type="line"
      height={250}
      width="100%"
      key={id}
    />
  );
};

export default memo(PriceChart);
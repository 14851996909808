import { useState, useEffect, useMemo, useCallback } from "react";
import { useFilterContext } from "./ContextManager";

import style from "../css/filter.module.css";
import "../index.css";

import logo from "../assets/logo/NEA-dark-horizontal-small.png";

//Variables
import { countriesListObj } from "../utils/variables";
import { isoLatLong } from "../utils/variables";

import { DisclaimerModal } from "./DisclaimerModal";
import { FeedbackModal } from "./FeedbackModal";

//utility functions
import {
  debounce,
  airportIdMap,
  timeConverter,
  createAirportRouteCountMap,
} from "../utils/utilityFunctions";

import { getStoredCustomAirports } from "../utils/customAirportFunctions";

import {
  fetchAndStoreTimestampAndCountriesList,
  getCountryData,
} from "../utils/indexedDBFunctions";

//components
import { CustomSlider } from "./UX/CustomSlider/CustomSlider";
import { CustomPopper } from "./UX/CustomPopper/CustomPopper";
import { RestoreDefaultValuesButton } from "./UX/RestoreDefaultValuesButton/RestoreDefaultValuesButton";
import { CustomAirportList } from "./CustomAirportList";
import { PlaneProfiles } from "./PlaneProfiles";

//materialUI components
import Switch from "@mui/material/Switch";
import Fab from "@mui/material/Fab";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip"; // For rendering selected countries with a removal icon
import CancelIcon from "@mui/icons-material/Cancel"; // Import the icon for removal
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import FilterTooltip from "./UX/FilterTooltip/FilterTooltip";

export function Filter({ map }) {
  const {
    filters,
    updateFilters,
    airportsData,
    setAirportsData,
    routesData,
    setRoutesData,
    setFilteredAirportsData,
    setFilteredRoutesData,
    selectedAirport,
    updateAirportIdMap,
    lastMapCenter,
    lastMapZoom,
    updateLoadingState,
    newCustomAirportCount,
    updateAirportRouteCountMap,
  } = useFilterContext();

  // States for storing original and user-filtered data
  const [isTimeCountryReady, setIsTimeCountryReady] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [autocompleteValue, setAutocompleteValue] = useState(null); // To control the country autocomplete input value
  const [inputText, setInputText] = useState(""); // useState to control the input text
  const [customAirports, setCustomAirports] = useState([]);
  const [airportRouteCountMap, setAirportRouteCountMap] = useState({});

  const [sliderValues, setSliderValues] = useState({
    minDistance: filters.minDistance,
    maxDistance: filters.maxDistance,
    minRunwayLength: filters.minRunwayLength,
    maxRunwayLength: filters.maxRunwayLength,
    timeSavedValue: filters.timeSavedValue,
    // ratioValue: filters.ratioValue,
    speed: filters.speed,
    departure: filters.departure,
    arrival: filters.arrival,
    userSizeMultiplier: filters.userSizeMultiplier,
  });

  // Storing only certain values from filters that will be used as dependencies for route useEffects and useMemos
  const relevantRouteFilters = useMemo(() => {
    const {
      minDistance,
      maxDistance,
      routeType,
      commercial,
      pso,
      routesPax,
      travelType,
      // ratioActive,
      timeSavedActive,
      // ratioValue,
      timeSavedValue,
      speed,
      departure,
      arrival,
      // Add any other properties needed
    } = filters;

    return {
      minDistance,
      maxDistance,
      routeType,
      commercial,
      pso,
      routesPax,
      travelType,
      // ratioActive,
      timeSavedActive,
      // ratioValue,
      timeSavedValue,
      speed,
      departure,
      arrival,
    };
  }, [
    filters.minDistance,
    filters.maxDistance,
    filters.routeType,
    filters.commercial,
    filters.pso,
    filters.routesPax,
    filters.travelType,
    // filters.ratioActive,
    filters.timeSavedActive,
    // filters.ratioValue,
    filters.timeSavedValue,
    filters.speed,
    filters.departure,
    filters.arrival,
  ]);

  // Storing only certain values from filters that will be used as dependencies for airports useEffects and useMemos
  const relevantAirportFilters = useMemo(() => {
    const {
      activeAirport,
      airportPax,
      country,
      custom,
      maxRunwayLength,
      minRunwayLength,
      scheduledService,
      type,
      // ratioActive,
      timeSavedActive,
      // Add any other properties needed
    } = filters;

    return {
      activeAirport,
      airportPax,
      country,
      custom,
      maxRunwayLength,
      minRunwayLength,
      scheduledService,
      type,
      // ratioActive,
      timeSavedActive,
    };
  }, [
    filters.activeAirport,
    filters.airportPax,
    filters.country,
    filters.custom,
    filters.maxRunwayLength,
    filters.minRunwayLength,
    filters.scheduledService,
    filters.type,
    // filters.ratioActive,
    filters.timeSavedActive,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchAndStoreTimestampAndCountriesList();
      setIsTimeCountryReady(true);
    };

    fetchData();
  }, []);

  const [menuClasses, setMenuClasses] = useState(
    `${style.filterMenu} ${style.menuStart}`
  );
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  useEffect(() => {
    // Function to fetch countries list from IndexedDB
    if (isTimeCountryReady) {
      // Convert the countries list object to an array of { code, label } objects
      const countries = Object.entries(countriesListObj).map(
        ([code, label]) => ({
          code: code.replace(/_/g, "-"), // Replace "_" with "-" in code
          label,
        })
      );
      // Sort the array alphabetically by the label
      countries.sort((a, b) => a.code.localeCompare(b.code));
      setCountriesList(countries);
    }
  }, [isTimeCountryReady]);

  //Set custom user defined airports
  useEffect(() => {
    const useStoredCustomAirports = async () => {
      const customAirportsArray = await getStoredCustomAirports();

      if (filters.custom && customAirportsArray.length > 0) {
        setCustomAirports(customAirportsArray);
      }

      if (!filters.custom || customAirportsArray.length === 0) {
        setCustomAirports([]);
      }
    };

    useStoredCustomAirports();
  }, [filters.custom, newCustomAirportCount]);

  // useEffect to fetch Main Data
  useEffect(() => {
    const initializeData = async () => {
      try {
        updateLoadingState(true); // Start loading
        let fetchedData = await getCountryData(filters.country);
        let fetchedAirportsData = [];
        let fetchedRoutesData = [];

        if (fetchedData.airports && fetchedData.airports.length > 0) {
          fetchedAirportsData = fetchedData.airports;
        }

        if (fetchedData.routes && fetchedData.routes.length > 0) {
          fetchedRoutesData = fetchedData.routes;
        }

        if (customAirports.length > 0) {
          let customAirportsArray = [];
          let customRoutesArray = [];

          for (let airport of customAirports) {
            customAirportsArray.push(airport.airport);
            for (let route of airport.routes) {
              customRoutesArray.push(route);
            }
          }
          fetchedAirportsData = fetchedAirportsData.concat(customAirportsArray);
          fetchedRoutesData = fetchedRoutesData.concat(customRoutesArray);
        }

        if (fetchedAirportsData.length > 0) {
          setAirportsData(fetchedAirportsData);
          updateAirportIdMap(airportIdMap(fetchedAirportsData));
        }

        if (fetchedRoutesData.length > 0) {
          setRoutesData(fetchedRoutesData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle any errors e.g., by setting error state or notifying the user
      } finally {
        updateLoadingState(false); // Stop loading regardless of success or failure
      }
    };

    if (isTimeCountryReady) {
      initializeData();
    }
  }, [filters.country, isTimeCountryReady, customAirports]); // Ensure the effect is triggered when these dependencies change

  useEffect(() => {
    updateAirportRouteCountMap(airportRouteCountMap);
  }, [airportRouteCountMap]);

  // useMemo for computationally intensive operations
  const filteredAirports = useMemo(() => {
    // Check if airportsData is not null before filtering
    return airportsData ? applyFiltersToAirports(airportsData, filters) : [];
  }, [airportsData, relevantAirportFilters, customAirports]);

  // useMemo for computationally intensive operations
  const filteredRoutes = useMemo(() => {
    // Check if routesData is not null before filtering
    return routesData
      ? applyFiltersToRoutes(
          routesData,
          filters,
          filteredAirports,
          selectedAirport
        )
      : [];
  }, [routesData, relevantRouteFilters, filteredAirports, selectedAirport]);

  // Update context when filtered data changes
  useEffect(() => {
    setFilteredAirportsData(filteredAirports);
  }, [filteredAirports, selectedAirport]);

  useEffect(() => {
    setFilteredRoutesData(filteredRoutes);
  }, [filteredAirports, filteredRoutes, selectedAirport]);

  // Function to apply filters to airports
  function applyFiltersToAirports(airports, filters) {
    // Add a check to ensure airports is an array, when fetching new data the data is read as an object with nested objects
    // for a few seconds until it is stored properly. this should be fixed
    if (!Array.isArray(airports)) {
      console.error("Expected an array of airports, received:", airports);
    }
    // If no airport types are selected, return an empty array
    if (
      filters.type.length === 0 ||
      filters.country.length === 0 ||
      filters.scheduledService.length === 0
    ) {
      return [];
    }
    return airports.filter((airport) => {
      // Bypass all checks if airport.custom exists and is true
      if (filters.type.includes("custom") && airport.type === "custom") {
        return true;
      }

      const typeMatch =
        filters.type.length === 0 || filters.type.includes(airport.type);
      const countryMatch =
        filters.country.length === 0 ||
        filters.country.includes(airport.country?.iso ?? "") || // Safe navigation with optional chaining and fallback
        filters.country.includes(airport.region?.iso ?? ""); // Safe navigation with optional chaining and fallback

      const serviceMatch =
        filters.scheduledService.length === 0 ||
        filters.scheduledService.includes(Boolean(airport.scheduledService));

      let runwayLengthMatch = false;
      if (airport.runways) {
        const runwayLengths = airport.runways.map((runway) => runway.length_m);
        const longestRunwayLength = Math.max(...runwayLengths);
        runwayLengthMatch =
          airport.type === "seaplane" ||
          (longestRunwayLength >= filters.minRunwayLength &&
            longestRunwayLength <= filters.maxRunwayLength);
      }

      // Check for runway length criteria bypass for seaplanes or evaluate runway length for other types

      const airportPaxMatch =
        !filters.airportPax || airport.pax >= filters.airportPax;

      return (
        typeMatch &&
        countryMatch &&
        serviceMatch &&
        runwayLengthMatch &&
        airportPaxMatch
      );
    });
  }

  // Function to apply filters to routes
  function applyFiltersToRoutes(
    routes,
    filters,
    filteredAirports,
    selectedAirport
  ) {
    if (!Array.isArray(routes)) {
      console.error("Expected an array of routes, received:", routes);
    }

    if (filters.type.length === 0) {
      return [];
    }

    // Initialize route count for all airport IDs
    let routeCount = createAirportRouteCountMap(filteredAirports);

    // Parse distance filters
    const minDistance =
      filters.minDistance !== undefined
        ? parseFloat(filters.minDistance)
        : -Infinity;
    const maxDistance =
      filters.maxDistance !== undefined
        ? parseFloat(filters.maxDistance)
        : Infinity;

    if (minDistance === 0 && maxDistance === 0) {
      return [];
    }

    const filteredAirportIds = new Set(
      filteredAirports.map((airport) => airport.airportId)
    );
    const filteredRoutes = [];

    for (const route of routes) {
      const routeDistance = parseFloat(route.distance);

      const airport1Included = filteredAirportIds.has(route.airport_1);
      const airport2Included = filteredAirportIds.has(route.airport_2);
      const commercialRoutes =
        !filters.commercial || route.commercial === true || route.pso === true;
      const psoRoutes = !filters.pso || (filters.pso && route.pso === true);
      const routesPaxMatch =
        !filters.routesPax || route.pax >= filters.routesPax;
      const routeTypeMatch =
        !filters.routeType || filters.routeType.includes(route.type);

      let travelTimeComparison = true;
      let timeSaved = null;

      if (filters.travelType.length > 0 && route.travel) {
        let timeArray = filters.travelType
          .map((type) => route.travel[type])
          .filter((time) => typeof time === "number");

        if (timeArray.length > 0) {
          const shortestTime = Math.min(...timeArray);
          const flightTime =
            route.travel.city +
            filters.departure +
            filters.arrival +
            Math.ceil((route.distance / filters.speed) * 60);

          const timeSavedComparison = filters.timeSavedActive
            ? shortestTime - flightTime >= filters.timeSavedValue
            : true;

          if (filters.timeSavedActive) {
            timeSaved = shortestTime - flightTime;
          }

          travelTimeComparison = timeSavedComparison;

          if (travelTimeComparison) {
            // if (ratio !== null) {
            //   route.ratio = Number(ratio.toFixed(2));
            // }
            if (timeSaved !== null) {
              route.timeSaved = timeSaved;
            }
          }
        } else {
          travelTimeComparison = false;
        }
      } else {
        if (!filters.timeSavedActive || filters.travelType.length === 0) {
          travelTimeComparison = true;
        } else {
          travelTimeComparison = false;
        }
      }

      let isSelectedAirportRoute = true;
      if (selectedAirport) {
        isSelectedAirportRoute =
          route.airport_1 === selectedAirport.id ||
          route.airport_2 === selectedAirport.id;
      }

      const routePassesFilter =
        routeDistance >= minDistance &&
        routeDistance <= maxDistance &&
        airport1Included &&
        airport2Included &&
        routeTypeMatch &&
        travelTimeComparison &&
        (filters.commercial && filters.pso
          ? commercialRoutes || psoRoutes
          : commercialRoutes && psoRoutes) &&
        routesPaxMatch;

      if (routePassesFilter) {
        routeCount[route.airport_1] = (routeCount[route.airport_1] || 0) + 1;
        routeCount[route.airport_2] = (routeCount[route.airport_2] || 0) + 1;

        if (isSelectedAirportRoute) {
          filteredRoutes.push(route);
        }
      }
    }

    setAirportRouteCountMap(routeCount);
    return filteredRoutes;
  }

  useEffect(() => {
    if (lastMapCenter) {
      updateFilters((prevFilters) => ({
        ...prevFilters,
        mapCenter: lastMapCenter,
      }));
    }
  }, [lastMapCenter]);

  useEffect(() => {
    if (lastMapZoom) {
      updateFilters((prevFilters) => ({
        ...prevFilters,
        mapZoom: lastMapZoom,
      }));
    }
  }, [lastMapZoom]);

  // Handlers for filter changes
  function handleChange(e, key) {
    // Extract 'checked' status directly from the event target
    const checked = e.target.checked;

    // Initialize the variable to hold the parsed or original value
    let value = e.target.value;
    try {
      // Try to parse the value as JSON to handle boolean and numeric values properly
      value = JSON.parse(value);
    } catch (error) {
      // If JSON parsing fails, retain the original string value
    }

    // Update the filters state
    updateFilters((prevFilters) => {
      let newValue;

      // Check if the current filter is an array
      if (Array.isArray(prevFilters[key])) {
        // If it's an array and the checkbox is checked, add the value to the array
        if (checked) {
          newValue = [...prevFilters[key], value];
        } else {
          // If the checkbox is not checked, remove the value from the array
          newValue = prevFilters[key].filter((item) => item !== value);
        }
      } else if (
        typeof prevFilters[key] === "boolean" &&
        typeof value === "boolean"
      ) {
        // For boolean filters, toggle the state based on the 'checked' status and the parsed value
        newValue = checked ? value : !value;
      } else {
        // For other data types, simply set or clear the value based on 'checked'
        newValue = checked ? value : null;
      }

      // Return the updated filters object with the new value for the specified key
      return { ...prevFilters, [key]: newValue };
    });
  }

  //updating filters.colors with values from color picker, debounce to avoid excessive rerenders of map markers
  const debouncedHandleColorChange = useCallback(
    debounce((type, color, styleIndex) => {
      updateFilters((prevFilters) => ({
        ...prevFilters,
        colors: {
          ...prevFilters.colors,
          [type]: prevFilters.colors[type].map((c, index) =>
            index === styleIndex ? color : c
          ),
        },
      }));
    }, 200),
    []
  );

  const handleColorChange = (type, color, styleIndex) => {
    debouncedHandleColorChange(type, color, styleIndex);
  };

  useEffect(() => {
    setSliderValues({
      ...sliderValues,
      minDistance: filters.minDistance,
      maxDistance: filters.maxDistance,
      minRunwayLength: filters.minRunwayLength,
      maxRunwayLength: filters.maxRunwayLength,
      timeSavedValue: filters.timeSavedValue,
      // ratioValue: filters.ratioValue,
      speed: filters.speed,
      departure: filters.departure,
      arrival: filters.arrival,
    });
  }, [
    filters.minDistance,
    filters.maxDistance,
    filters.minRunwayLength,
    filters.maxRunwayLength,
    filters.timeSavedValue,
    // filters.ratioValue,
    filters.speed,
    filters.departure,
    filters.arrival,
  ]);

  const updateSliderValue = (newValue, keys) => {
    let newSliderValues = { ...sliderValues };
    keys.forEach((key, i) => {
      newSliderValues[key] = newValue[i];
    });
    setSliderValues(newSliderValues);
  };

  const debounceOnSliderChangeComitted = useCallback(
    debounce((newValue, keys) => {
      let newFilterValues = { ...filters };
      keys.forEach((key, i) => {
        newFilterValues[key] = newValue[i];
      });
      updateFilters(newFilterValues);
    }, 250)
  );

  const onSliderChangeCommitted = (newValue, keys) => {
    // Update filters state when slider change is committed
    debounceOnSliderChangeComitted(newValue, keys);
  };

  //DISABLED

  const carTransitDisabled = (travelType) => {
    // disabled slider/switch
    return !travelType.includes("car") && !travelType.includes("transit");
  };

  const domesticInternationalDisabled = () => {
    //disabled switches for routes
    return (
      !filters.routeType.includes("domestic") &&
      !filters.routeType.includes("international")
    );
  };

  const airportsDisabled = () => {
    return (
      !filters.type.includes("large") &&
      !filters.type.includes("medium") &&
      !filters.type.includes("small") &&
      !filters.type.includes("seaplane")
    );
  };

  const disabledTextField = carTransitDisabled(filters.travelType); // disabled textfield

  const toggleMenu = () => {
    if (menuIsOpen) {
      // Replace menuOpen with menuClose
      setMenuClasses(`${style.filterMenu} ${style.menuClose}`);
    } else {
      // Replace menuClose with menuOpen
      setMenuClasses(`${style.filterMenu} ${style.menuOpen}`);
    }
    // Toggle the state
    setMenuIsOpen(!menuIsOpen);
  };

  const moveToLocation = (lat, lng, zoom = null) => {
    setTimeout(() => {
      const location = new google.maps.LatLng(lat, lng);
      map.panTo(location);
      if (zoom !== null) {
        map.setZoom(zoom);
      }
    }, 500); // 50ms delay
  };

  const handleCountrySelect = (event, newValue) => {
    if (newValue && !filters.country.includes(newValue.code)) {
      updateFilters((prevFilters) => ({
        ...prevFilters,
        country: [...prevFilters.country, newValue.code],
      }));

      // Retrieve coordinates and pan to location
      const coords = isoLatLong[newValue.code];
      if (coords) {
        const [lat, lng] = coords.split(",").map(Number);
        moveToLocation(lat, lng, 6); // No zoom specified, will use current zoom
      }
    }
    setInputText("");
  };

  // Function to handle the removal of a country from the selected list
  const handleCountryRemove = (countryCode) => {
    updateFilters((prevFilters) => ({
      ...prevFilters,
      country: prevFilters.country.filter((code) => code !== countryCode),
    }));
  };

  useEffect(() => {}, [filters.travelType]);

  return (
    <>
      <div className={style.menuButton}>
        <Fab
          color="primary"
          aria-label="open-menu"
          onClick={toggleMenu}
          sx={{ width: "2.5rem", height: "2.5rem" }}
        >
          <MenuIcon
            sx={{
              width: "2rem",
              height: "2rem",
              color: "var(--nea-white-color)",
            }}
          />
        </Fab>
      </div>
      <div className={menuClasses}>
        <div className={style.header}>
          <div className={style.logoCloseButtonContainer}>
            <img src={logo} alt="NEA Logo" />
            <div className={style.menuCloseButton}>
              <IconButton
                aria-label="close menu"
                size="small"
                onClick={toggleMenu}
              >
                <CloseIcon style={{ color: "rgba(125, 125, 125, 0.9)" }} />
              </IconButton>
            </div>
          </div>
          <div className={style.results}>
            <p>
              <span>Routes:</span>
              <span>{filteredRoutes.length}</span>
            </p>
            <p>
              <span>Airports:</span>
              <span>{filteredAirports.length}</span>
            </p>
          </div>
        </div>

        <div className={style.menuContentWrapper}>
          <div className={style.menuContent}>
            {" "}
            <label>
              <FilterTooltip id="distance">
                <span>Distance (km)</span>
              </FilterTooltip>
              <CustomSlider
                sliderValue={[
                  sliderValues.minDistance,
                  sliderValues.maxDistance,
                ]}
                filterKeys={["minDistance", "maxDistance"]}
                updateSliderValue={updateSliderValue}
                onSliderChangeCommitted={onSliderChangeCommitted}
                step={10}
                max={400}
              />
            </label>
            <label>
              <FilterTooltip id="length">
                <span>Runway Length (m)</span>
              </FilterTooltip>
              <CustomSlider
                sliderValue={[
                  sliderValues.minRunwayLength,
                  sliderValues.maxRunwayLength,
                ]}
                filterKeys={["minRunwayLength", "maxRunwayLength"]}
                updateSliderValue={updateSliderValue}
                onSliderChangeCommitted={onSliderChangeCommitted}
                step={10}
                max={6000}
              />
            </label>
            <PlaneProfiles />
            {/* AIRPORTS */}
            <div className={style.typeSection}>
              <Accordion
                sx={{
                  background: "inherit",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p>Airports</p>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={`${style.typeSection} ${style.gap}`}>
                    {" "}
                    <div>
                      {" "}
                      <div className={style.typeContainer}>
                        <label>
                          <Switch
                            value="large"
                            checked={filters.type.includes("large")}
                            onChange={(e) => handleChange(e, "type")}
                          />
                          <FilterTooltip id="large">
                            <span>Large</span>
                          </FilterTooltip>
                        </label>
                        <input
                          type="color"
                          className={style.typeColorPicker}
                          value={
                            filters.colors.large[filters.currentColorIndex]
                          } //default color from filters state
                          onChange={(e) =>
                            handleColorChange(
                              "large",
                              e.target.value,
                              filters.currentColorIndex
                            )
                          }
                        />
                      </div>
                      <div className={style.typeContainer}>
                        <label>
                          <Switch
                            value="medium"
                            checked={filters.type.includes("medium")}
                            onChange={(e) => handleChange(e, "type")}
                          />
                          <FilterTooltip id="medium">
                            <span>Medium</span>
                          </FilterTooltip>
                        </label>
                        <input
                          type="color"
                          className={style.typeColorPicker}
                          value={
                            filters.colors.medium[filters.currentColorIndex]
                          } //default color from filters state
                          onChange={(e) =>
                            handleColorChange(
                              "medium",
                              e.target.value,
                              filters.currentColorIndex
                            )
                          }
                        />
                      </div>
                      <div className={style.typeContainer}>
                        <label>
                          <Switch
                            value="small"
                            checked={filters.type.includes("small")}
                            onChange={(e) => handleChange(e, "type")}
                          />
                          <FilterTooltip id="small">
                            <span>Small</span>
                          </FilterTooltip>
                        </label>
                        <input
                          type="color"
                          className={style.typeColorPicker}
                          value={
                            filters.colors.small[filters.currentColorIndex]
                          } //default color from filters state
                          onChange={(e) =>
                            handleColorChange(
                              "small",
                              e.target.value,
                              filters.currentColorIndex
                            )
                          }
                        />
                      </div>
                      <div className={style.typeContainer}>
                        <label>
                          <Switch
                            value="seaplane"
                            checked={filters.type.includes("seaplane")}
                            onChange={(e) => handleChange(e, "type")}
                          />
                          <FilterTooltip id="seaplane">
                            <span>Seaplane</span>
                          </FilterTooltip>
                        </label>
                        <input
                          type="color"
                          className={style.typeColorPicker}
                          value={
                            filters.colors.seaplane[filters.currentColorIndex]
                          } //default color from filters state
                          onChange={(e) =>
                            handleColorChange(
                              "seaplane",
                              e.target.value,
                              filters.currentColorIndex
                            )
                          }
                        />
                      </div>
                      {/* <CustomSlider
                        sliderValue={[sliderValues.userSizeMultiplier]}
                        filterKeys={["userSizeMultiplier"]}
                        updateSliderValue={updateSliderValue}
                        onSliderChangeCommitted={onSliderChangeCommitted}
                        step={0.1}
                        min={0}
                        max={3}
                      /> */}
                    </div>
                    <div>
                      {" "}
                      <div className={style.typeSection}>
                        <div className={style.typeContainer}>
                          <label>
                            <Switch
                              value="true"
                              checked={filters.scheduledService.includes(true)}
                              onChange={(e) =>
                                handleChange(e, "scheduledService")
                              }
                              disabled={airportsDisabled()}
                            />
                            <FilterTooltip id="scheduledairport">
                              <span
                                className={
                                  airportsDisabled() ? style.disabledText : ""
                                }
                              >
                                Has scheduled flights
                              </span>
                            </FilterTooltip>
                          </label>
                        </div>
                        <div className={style.typeContainer}>
                          <label>
                            <Switch
                              value="false"
                              checked={filters.scheduledService.includes(false)}
                              onChange={(e) =>
                                handleChange(e, "scheduledService")
                              }
                              disabled={airportsDisabled()}
                            />
                            <FilterTooltip id="noscheduledairport">
                              <span
                                className={
                                  airportsDisabled() ? style.disabledText : ""
                                }
                              >
                                No scheduled flights
                              </span>
                            </FilterTooltip>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className={style.typeSection}>
                      <FilterTooltip id="airportpax">
                        <TextField
                          label="Passengers/Year"
                          type="number"
                          name="airportPax"
                          value={filters.airportPax || ""}
                          onChange={(e) =>
                            updateFilters({
                              ...filters,
                              airportPax: Number(e.target.value) || 0,
                            })
                          }
                          variant="outlined"
                          size="small"
                          fullWidth
                          disabled={airportsDisabled()}
                          className={
                            airportsDisabled() ? style.disabledText : ""
                          }
                        />
                      </FilterTooltip>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            {/* ROUTES */}
            <div className={style.typeSection}>
              <Accordion sx={{ background: "inherit" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p>Routes</p>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={`${style.typeSection} ${style.gap}`}>
                    <div>
                      <div className={style.typeContainer}>
                        <label>
                          <Switch
                            value="domestic"
                            checked={filters.routeType.includes("domestic")}
                            onChange={(e) => handleChange(e, "routeType")}
                            size="small"
                          />
                          <FilterTooltip id="domestic">
                            <span>Domestic</span>
                          </FilterTooltip>
                        </label>
                        <input
                          type="color"
                          className={style.typeColorPicker}
                          value={
                            filters.colors.domestic[filters.currentColorIndex]
                          }
                          onChange={(e) =>
                            handleColorChange(
                              "domestic",
                              e.target.value,
                              filters.currentColorIndex
                            )
                          }
                        />
                      </div>
                      <div className={style.typeContainer}>
                        <label>
                          <Switch
                            value="international"
                            checked={filters.routeType.includes(
                              "international"
                            )}
                            onChange={(e) => handleChange(e, "routeType")}
                            size="small"
                          />
                          <FilterTooltip id="international">
                            <span>International</span>
                          </FilterTooltip>
                        </label>
                        <input
                          type="color"
                          className={style.typeColorPicker}
                          value={
                            filters.colors.international[
                              filters.currentColorIndex
                            ]
                          }
                          onChange={(e) =>
                            handleColorChange(
                              "international",
                              e.target.value,
                              filters.currentColorIndex
                            )
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <div className={style.typeContainer}>
                        <label>
                          <Switch
                            checked={filters.commercial}
                            onChange={(e) =>
                              updateFilters({
                                ...filters,
                                commercial: e.target.checked,
                              })
                            }
                            disabled={domesticInternationalDisabled()}
                          />
                          <FilterTooltip id="scheduledroute">
                            <span
                              className={
                                domesticInternationalDisabled()
                                  ? style.disabledText
                                  : ""
                              }
                            >
                              Scheduled
                            </span>
                          </FilterTooltip>
                        </label>
                        <input
                          type="color"
                          className={style.typeColorPicker}
                          value={
                            filters.colors.commercialRoute[
                              filters.currentColorIndex
                            ]
                          }
                          onChange={(e) =>
                            handleColorChange(
                              "commercialRoute",
                              e.target.value,
                              filters.currentColorIndex
                            )
                          }
                        />
                      </div>
                      <div className={style.typeContainer}>
                        <label>
                          <Switch
                            checked={filters.pso}
                            onChange={(e) =>
                              updateFilters({
                                ...filters,
                                pso: e.target.checked,
                              })
                            }
                            disabled={domesticInternationalDisabled()}
                          />
                          <FilterTooltip id="pso">
                            <span
                              className={
                                domesticInternationalDisabled()
                                  ? style.disabledText
                                  : ""
                              }
                            >
                              PSO
                            </span>
                          </FilterTooltip>
                        </label>
                        <input
                          type="color"
                          className={style.typeColorPicker}
                          value={
                            filters.colors.psoRoute[filters.currentColorIndex]
                          }
                          onChange={(e) =>
                            handleColorChange(
                              "psoRoute",
                              e.target.value,
                              filters.currentColorIndex
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className={style.typeSection}>
                      <div className={style.typeContainer}>
                        <FilterTooltip id="routepax">
                          <TextField
                            label="Passengers/Year"
                            type="number"
                            name="routesPax"
                            value={filters.routesPax || ""}
                            onChange={(e) =>
                              updateFilters({
                                ...filters,
                                routesPax: Number(e.target.value) || 0,
                              })
                            }
                            variant="outlined"
                            size="small"
                            fullWidth
                            disabled={domesticInternationalDisabled()}
                            className={
                              domesticInternationalDisabled()
                                ? style.disabledText
                                : ""
                            }
                          />
                        </FilterTooltip>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            {/* ROUTE COMPARISON */}
            <div className={style.typeSection}>
              <Accordion
                sx={{
                  background: "inherit",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    "&.MuiAccordionSummary-root": {
                      margin: 0,
                    },
                    "& .MuiAccordionSummary-content": {
                      // Target the content wrapper specifically if needed
                      margin: 0,
                    },
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                      // Target the content wrapper specifically if needed
                      margin: 0,
                    },
                  }}
                >
                  <FilterTooltip id="compare">
                    <span>Route Comparison</span>
                  </FilterTooltip>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={`${style.typeSection} ${style.gap}`}>
                    <div className={`${style.typeSection} ${style.gap}`}>
                      <div className={`${style.typeSection}`}>
                        <label
                          className={`${style.typeContainer} ${style.ratioSwitchContainer}`}
                        >
                          <FilterTooltip id="driving">
                            <span>Car</span>
                          </FilterTooltip>
                          <Switch
                            value="car"
                            checked={filters.travelType.includes("car")}
                            onChange={(e) => handleChange(e, "travelType")}
                          />
                        </label>
                        <label
                          className={`${style.typeContainer} ${style.ratioSwitchContainer}`}
                        >
                          <FilterTooltip id="publictransport">
                            <span>Public Transport</span>
                          </FilterTooltip>
                          <Switch
                            value="transit"
                            checked={filters.travelType.includes("transit")}
                            onChange={(e) => handleChange(e, "travelType")}
                          />
                        </label>
                      </div>
                      <div>
                        <div
                          className={`${style.flex} ${style.w100} ${style.gap}`}
                        >
                          <div className={`${style.flexBetween} ${style.w100}`}>
                            <FilterTooltip id="saved">
                              <span
                                className={
                                  disabledTextField ? style.disabledText : ""
                                }
                              >
                                Time Saved
                              </span>
                            </FilterTooltip>
                            <span
                              className={
                                disabledTextField ? style.disabledText : ""
                              }
                            >
                              {timeConverter(filters.timeSavedValue)}
                            </span>
                          </div>

                          <Switch
                            value="true"
                            checked={filters.timeSavedActive === true}
                            onChange={(e) => handleChange(e, "timeSavedActive")}
                            disabled={carTransitDisabled(filters.travelType)}
                          />
                        </div>

                        <CustomSlider
                          sliderValue={[sliderValues.timeSavedValue]}
                          filterKeys={["timeSavedValue"]}
                          updateSliderValue={updateSliderValue}
                          onSliderChangeCommitted={onSliderChangeCommitted}
                          max={300}
                          step={5}
                          disabled={carTransitDisabled(filters.travelType)}
                        />
                      </div>
                      {/* <div>
                        <div
                          className={`${style.flex} ${style.w100} ${style.gap}`}
                        >
                          <div className={`${style.flexBetween} ${style.w100}`}>
                            <FilterTooltip id="ratio">
                              <span
                                className={
                                  disabledTextField ? style.disabledText : ""
                                }
                              >
                                Ratio
                              </span>
                            </FilterTooltip>
                            <span
                              className={
                                disabledTextField ? style.disabledText : ""
                              }
                            >
                              {filters.ratioValue}
                            </span>
                          </div>

                          <Switch
                            value="true"
                            checked={filters.ratioActive === true}
                            onChange={(e) => handleChange(e, "ratioActive")}
                            disabled={carTransitDisabled(filters.travelType)}
                          />
                        </div>
                        <CustomSlider
                          sliderValue={[sliderValues.ratioValue]}
                          filterKeys={["ratioValue"]}
                          updateSliderValue={updateSliderValue}
                          onSliderChangeCommitted={onSliderChangeCommitted}
                          max={5}
                          step={0.1}
                          disabled={carTransitDisabled(filters.travelType)}
                        />
                      </div> */}
                    </div>

                    <div>
                      <div className={style.flexBetween}>
                        <FilterTooltip id="speed">
                          <span>Flight Speed</span>
                        </FilterTooltip>
                        <p>{filters.speed} km/h</p>
                      </div>
                      <CustomSlider
                        sliderValue={[sliderValues.speed]}
                        filterKeys={["speed"]}
                        updateSliderValue={updateSliderValue}
                        onSliderChangeCommitted={onSliderChangeCommitted}
                        step={5}
                        min={50}
                        max={500}
                      />
                    </div>
                    <div>
                      <div className={style.flexBetween}>
                        <FilterTooltip id="departure">
                          <span>Airport Time (Departure)</span>
                        </FilterTooltip>
                        <p>{timeConverter(filters.departure)}</p>
                      </div>
                      <CustomSlider
                        sliderValue={[sliderValues.departure]}
                        filterKeys={["departure"]}
                        updateSliderValue={updateSliderValue}
                        onSliderChangeCommitted={onSliderChangeCommitted}
                        step={5}
                        max={120}
                      />
                    </div>
                    <div>
                      <div className={style.flexBetween}>
                        <FilterTooltip id="arrival">
                          <span>Airport Time (Arrival)</span>
                        </FilterTooltip>
                        <p>{timeConverter(filters.arrival)}</p>
                      </div>
                      <CustomSlider
                        sliderValue={[sliderValues.arrival]}
                        filterKeys={["arrival"]}
                        updateSliderValue={updateSliderValue}
                        onSliderChangeCommitted={onSliderChangeCommitted}
                        step={5}
                        max={120}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            {/* CUSTOM AIRPORTS */}
            <div className={style.typeSection}>
              <Accordion
                sx={{
                  background: "inherit",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <FilterTooltip id="customsection">
                    <p>Custom Airports</p>
                  </FilterTooltip>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={`${style.typeSection} ${style.gap}`}>
                    <div className={style.typeSection}>
                      <div className={style.typeContainer}>
                        <FilterTooltip id="customairport">
                          <label>
                            <Switch
                              value="custom"
                              checked={filters.type.includes("custom")}
                              onChange={(e) => handleChange(e, "type")}
                            />
                            Custom Airports
                          </label>
                        </FilterTooltip>
                        <input
                          type="color"
                          className={style.typeColorPicker}
                          value={
                            filters.colors.custom[filters.currentColorIndex]
                          } //default color from filters state
                          onChange={(e) =>
                            handleColorChange("custom", e.target.value)
                          }
                        />
                      </div>
                      <div className={style.typeContainer}>
                        <FilterTooltip id="customroutes">
                          <label>
                            <Switch
                              value="custom"
                              checked={filters.routeType.includes("custom")}
                              onChange={(e) => handleChange(e, "routeType")}
                              size="small"
                            />
                            Custom Routes
                          </label>
                        </FilterTooltip>
                        <input
                          type="color"
                          className={style.typeColorPicker}
                          value={
                            filters.colors.customRoute[
                              filters.currentColorIndex
                            ]
                          } //default color from filters state
                          onChange={(e) =>
                            handleColorChange("customRoute", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <CustomAirportList />
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            {/* COUNTRY PICKER */}
            <div className={style.typeSection}>
              <div className={style.typeContainer}>
                <Autocomplete
                  className={style.countrySelect}
                  sx={{ width: "100%" }}
                  options={countriesList}
                  getOptionLabel={(option) => option.label}
                  value={autocompleteValue} // Control the input value
                  onChange={handleCountrySelect} // Handle the selection of a country
                  inputValue={inputText} // Control the text displayed in the input
                  onInputChange={(event, newInputValue) => {
                    setInputText(newInputValue); // Update the text as the user types
                  }}
                  PopperComponent={CustomPopper} // Use the custom Popper component
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{
                        "& > img": { mr: 2, flexShrink: 0 },
                      }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        //Splits option.code from f.ex. "US-TX" to just "US" to get the correct flag
                        src={`https://flagcdn.com/w20/${option.code
                          .split("-")[0]
                          .toLowerCase()}.webp`}
                        alt={`Flag of ${option.label}`}
                      />
                      {option.label} ({option.code})
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Add Country" /> // temporary fix for NEA due to short list of options for input - revert for Elfly
                  )}
                />
              </div>
              <div>
                {/* Render selected countries as list items */}
                <Box sx={{ mt: 2, ml: 1 }}>
                  {filters.country.map((countryCode) => {
                    const country = countriesList.find(
                      (c) => c.code === countryCode
                    );
                    return (
                      <Chip
                        key={countryCode}
                        label={country ? country.label : countryCode}
                        onDelete={() => handleCountryRemove(countryCode)}
                        deleteIcon={<CancelIcon />}
                        sx={{ mr: 1, mb: 1 }}
                      />
                    );
                  })}
                </Box>
              </div>
            </div>
            <div
              className={`${style.w100} ${style.flex} ${style.justCenter} ${style.marginT}`}
            >
              <RestoreDefaultValuesButton />
            </div>
            {/* DISCLAIMER & FEEDBACK */}
            <div className={style.disclaimerFeedbackCopyrightContainer}>
              {" "}
              <div className={style.disclaimerFeedbackContainer}>
                <div className={style.disclaimerFeedbackText}>
                  <DisclaimerModal />
                </div>
                <div className={style.disclaimerFeedbackText}>
                  <FeedbackModal />
                </div>
              </div>
              {/* COPYRIGHT */}
              <div>
                <p className={style.copyright}>
                  Copyright &#169; 2024 Nordic Network for Electric Aviation
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { useRef, useEffect, useState } from "react";
import style from "../css/loadDataConfirmationModal.module.css";

export function LoadDataConfirmationModal({
  visible,
  onConfirm,
  onCancel,
  countryName,
  mousePosition,
}) {
  const [modalPosition, setModalPosition] = useState(null);

  const modalRef = useRef(null);

  useEffect(() => {
    if (mousePosition && modalRef.current) {
      const { width, height } = modalRef.current.getBoundingClientRect();
      const padding = 10; // Padding from the edge of the window

      let adjustedX = mousePosition.x;
      let adjustedY = mousePosition.y;

      // Adjust if the modal goes out of the viewport horizontally
      if (adjustedX + width + padding > window.innerWidth) {
        adjustedX = window.innerWidth - width - padding;
      }

      // Adjust if the modal goes out of the viewport vertically
      if (adjustedY + height + padding > window.innerHeight) {
        adjustedY = window.innerHeight - height - padding;
      }

      setModalPosition({ top: adjustedY, left: adjustedX });
    }
  }, [mousePosition]);
  if (!visible || !mousePosition) return null;
  return (
    <div
      ref={modalRef}
      className={`${style.modal} ${visible ? style.fadeIn : ""}`}
      style={modalPosition}
    >
      <p>Do you want to load data for</p>
      <p className={style.country}>{countryName}?</p>
      <div className={style.buttons}>
        <button onClick={onConfirm} className={style.button}>
          Yes
        </button>
        <button onClick={onCancel} className={style.button}>
          No
        </button>
      </div>
    </div>
  );
}

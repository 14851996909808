import { capitalizeFirstLetter } from "./utilityFunctions";

/**
 * Groups routes by whole number ratios.
 */
// export function groupRoutesByRatio(routes) {
//   if (!Array.isArray(routes)) {
//     console.error("Invalid input: routes should be an array.");
//     return { ratioRanges: [], ratioCountsArray: [] };
//   }

//   const ratioCounts = {};

//   routes.forEach((route) => {
//     if (route && typeof route.ratio === "number") {
//       const group = Math.floor(route.ratio);
//       ratioCounts[group] = (ratioCounts[group] || 0) + 1;
//     } else {
//       console.warn("Invalid route object or missing 'ratio' property:", route);
//     }
//   });

//   const ratioRanges = Object.keys(ratioCounts).map(
//     (key) => `${key}-${parseInt(key) + 1}`
//   );
//   const ratioCountsArray = Object.values(ratioCounts);

//   return { ratioRanges, ratioCountsArray };
// }

/**
 * Groups routes by distance ranges.
 */
export function groupRoutesByDistance(routes) {
  if (!Array.isArray(routes)) {
    console.error("Invalid input: routes should be an array.");
    return [];
  }

  const ranges = [0, 100, 200, 300, 400];
  const distanceCounts = ranges.slice(0, -1).map((range, i) => {
    const nextRange = ranges[i + 1];
    const count = routes.filter(
      (r) =>
        r &&
        typeof r.distance === "number" &&
        r.distance >= range &&
        r.distance < nextRange
    ).length;

    return {
      range: `${range}-${nextRange}`,
      count,
    };
  });

  // Remove groups with zero counts
  return distanceCounts.filter((group) => group.count > 0);
}

/**
 * Groups routes by time saved ranges.
 */
export function groupRoutesByTimeSaved(routes) {
  if (!Array.isArray(routes)) {
    console.error("Invalid input: routes should be an array.");
    return [];
  }

  const ranges = [0, 60, 120, 180, 240, 300]; // Time in minutes
  const timeSavedCounts = ranges.slice(0, -1).map((range, i) => {
    const nextRange = ranges[i + 1];
    const count = routes.filter(
      (r) =>
        r &&
        typeof r.timeSaved === "number" &&
        r.timeSaved >= range &&
        r.timeSaved < nextRange
    ).length;

    return {
      range: `${range / 60}-${nextRange / 60} hrs`,
      count,
    };
  });

  // Add the last group for time saved over the last range
  const lastRange = ranges[ranges.length - 1];
  const lastCount = routes.filter(
    (r) => r && typeof r.timeSaved === "number" && r.timeSaved >= lastRange
  ).length;

  if (lastCount > 0) {
    timeSavedCounts.push({
      range: `${lastRange / 60}+ hrs`,
      count: lastCount,
    });
  }

  // Remove groups with zero counts
  return timeSavedCounts.filter((group) => group.count > 0);
}

/**
 * Groups routes by type (domestic or international).
 */
export function groupRoutesByType(routes) {
  if (!Array.isArray(routes)) {
    console.error("Invalid input: routes should be an array.");
    return { categories: [], counts: [] };
  }

  const typeCounts = { domestic: 0, international: 0 };

  routes.forEach((route) => {
    if (route && typeof route.type === "string") {
      if (route.type !== "custom") {
        if (route.type === "domestic") {
          typeCounts.domestic += 1;
        } else if (route.type === "international") {
          typeCounts.international += 1;
        } else {
          console.warn("Unknown route type:", route.type);
        }
      }
    } else {
      console.warn("Invalid route object or missing 'type' property:", route);
    }
  });

  return {
    categories: ["Domestic", "International"],
    counts: [typeCounts.domestic, typeCounts.international],
  };
}

/**
 * Counts the number of airports for each type.
 */
export function countAirportTypes(airports, types) {
  if (!Array.isArray(airports) || !Array.isArray(types)) {
    console.error("Invalid input: airports and types should be arrays.");
    return [];
  }

  const counts = types.reduce((acc, type) => {
    acc[type] = 0;
    return acc;
  }, {});

  airports.forEach((airport) => {
    if (airport && counts[airport.type] !== undefined) {
      counts[airport.type] += 1;
    } else if (airport) {
      console.warn("Unknown airport type:", airport.type);
    } else {
      console.warn("Invalid airport object:", airport);
    }
  });

  return Object.values(counts);
}

/**
 * Lists airport types, excluding 'custom'.
 */
export function listAirportTypes(filters) {
  if (!filters || !Array.isArray(filters.type)) {
    console.error("Invalid input: filters.type should be an array.");
    return [];
  }

  return filters.type.filter((type) => type !== "custom");
}

/**
 * Groups airports by country.
 */
export function groupAirportsByCountry(airports) {
  if (!Array.isArray(airports)) {
    console.error("Invalid input: airports should be an array.");
    return { countries: [], counts: [] };
  }

  const countryCounts = {};

  airports.forEach((airport) => {
    if (airport) {
      const countryName = airport.country?.name
        ? capitalizeFirstLetter(airport.country.name)
        : "N/A";
      countryCounts[countryName] = (countryCounts[countryName] || 0) + 1;
    } else {
      console.warn("Invalid airport object:", airport);
    }
  });

  return {
    countries: Object.keys(countryCounts),
    counts: Object.values(countryCounts),
  };
}

export function groupAirportsByCountryAndType(airports, types) {
  if (!Array.isArray(airports) || !Array.isArray(types)) {
    console.error("Invalid input: airports and types should be arrays.");
    return [];
  }

  const countryCounts = {};

  airports.forEach((airport) => {
    if (airport) {
      if (airport.type && airport.type === "custom") {
        return;
      }
      const countryName = capitalizeFirstLetter(airport.country?.name || "N/A");
      if (!countryCounts[countryName]) {
        countryCounts[countryName] = types.reduce((acc, type) => {
          acc[type] = 0;
          return acc;
        }, {});
      }
      if (countryCounts[countryName][airport.type] !== undefined) {
        countryCounts[countryName][airport.type] += 1;
      } else {
        // console.warn(
        //   `Unknown airport type '${airport.type}' for country '${countryName}'.`
        // );
      }
    } else {
      console.warn("Invalid airport object:", airport);
    }
  });

  return Object.entries(countryCounts).map(([country, typeCounts]) => ({
    country,
    typeCounts: Object.values(typeCounts),
  }));
}

export function groupAirportsByCountryAndRouteCount(airports, routeCounts) {
  if (!Array.isArray(airports) || typeof routeCounts !== "object") {
    console.error(
      "Invalid input: airports should be an array and routeCounts should be an object."
    );
    return [];
  }

  // Define the route count groups
  const groups = [
    { min: 0, max: 10, label: "0-10 Routes" },
    { min: 11, max: 30, label: "11-30 Routes" },
    { min: 31, max: 50, label: "31-50 Routes" },
    { min: 51, max: 75, label: "51-75 Routes" },
    { min: 76, max: Infinity, label: "76+ Routes" },
  ];

  const groupLabels = groups.map((g) => g.label);

  const countryCounts = {};

  airports.forEach((airport) => {
    if (airport) {
      if (airport.type && airport.type === "custom") {
        return;
      }
      const airportId = airport.airportId;
      const routeCount = routeCounts[airportId] || 0;
      const group = groups.find(
        (g) => routeCount >= g.min && routeCount <= g.max
      );

      if (group) {
        const groupLabel = group.label;

        const countryName = capitalizeFirstLetter(
          airport.country?.name || "N/A"
        );

        if (!countryCounts[countryName]) {
          // Initialize counts for all groups
          countryCounts[countryName] = {};
          groupLabels.forEach((label) => {
            countryCounts[countryName][label] = 0;
          });
        }

        countryCounts[countryName][groupLabel] += 1;
      } else {
        console.warn("No group found for routeCount:", routeCount);
      }
    } else {
      console.warn("Invalid airport object:", airport);
    }
  });

  // Prepare data for the chart
  return Object.entries(countryCounts).map(([country, groupCountsObj]) => ({
    country,
    groupCounts: groupLabels.map((label) => groupCountsObj[label]),
  }));
}

export function groupAirportsByRunwayLength(airports) {
  if (!Array.isArray(airports)) {
    console.error("Invalid input: airports should be an array.");
    return { categories: [], seriesData: [] };
  }

  const countryCounts = {};

  airports.forEach((airport) => {
    if (airport) {
      if (airport.type && airport.type === "custom") {
        return;
      }
      const countryName = capitalizeFirstLetter(
        airport.country?.name || "Unknown"
      );
      const runways = Array.isArray(airport.runways) ? airport.runways : [];

      // Initialize counts for the country if not already set
      if (!countryCounts[countryName]) {
        countryCounts[countryName] = { "750m": 0, "1100m": 0 };
      }

      // Check if any runway meets the requirement for each plane
      const meets1100mRequirement = runways.some(
        (runway) =>
          runway &&
          typeof runway.length_m === "number" &&
          runway.length_m >= 1100
      );
      const meets750mRequirement =
        meets1100mRequirement ||
        runways.some(
          (runway) =>
            runway &&
            typeof runway.length_m === "number" &&
            runway.length_m >= 750
        );

      // Increment counts based on runway requirements
      if (meets1100mRequirement) {
        countryCounts[countryName]["750m"] += 1;
        countryCounts[countryName]["1100m"] += 1;
      } else if (meets750mRequirement) {
        countryCounts[countryName]["750m"] += 1;
      }
    } else {
      console.warn("Invalid airport object:", airport);
    }
  });

  // Prepare data for the grouped bar chart
  const categories = Object.keys(countryCounts); // List of country names for the x-axis
  const seriesData = [
    {
      name: "750m",
      data: categories.map((country) => countryCounts[country]["750m"]),
    },
    {
      name: "1100m",
      data: categories.map((country) => countryCounts[country]["1100m"]),
    },
  ];

  return { categories, seriesData };
}

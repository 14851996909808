import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import CircleIcon from "@mui/icons-material/Circle";

import styled from "../css/disclaimerFeedbackModal.module.css";

export const DisclaimerModal = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const listItemStyles = {
    height: "fit-content",
    alignItems: "flex-start",
    padding: "0.3rem 0.5rem",
  };

  // Individual style objects for different MUI components
  const listItemIconStyles = {
    marginTop: "0.5rem",
    minWidth: "20px",
  };

  const circleIconStyles = {
    fontSize: "0.4rem",
  };

  const listItemTextStyles = {
    fontSize: "0.8rem",
    fontFamily: '"Open Sans", sans-serif',
    fontWeight: "500",
    margin: "0",
  };

  const typographyStyles = {
    mt: 2,
    fontFamily: '"Open Sans", sans-serif',
    fontSize: "0.8rem",
    fontWeight: "500",
  };

  const closeButtonStyles = {
    color: "rgba(125, 125, 125, 0.9)",
  };

  return (
    <div>
      <p onClick={handleOpen}>Disclaimer</p>
      <Modal
        sx={{ backdropFilter: "blur(0.2rem)" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styled.modal}>
          <div className={styled.header}>
            <Typography
              id="modal-modal-title"
              variant="h4"
              fontWeight="700"
              fontFamily='"Jaldi", sans-serif'
            >
              Disclaimer
            </Typography>
            <IconButton
              className={styled.closeButton}
              aria-label="close menu"
              size="small"
              onClick={handleClose}
            >
              <CloseIcon sx={closeButtonStyles} />
            </IconButton>
          </div>
          <div className={styled.mainTextWrapper}>
            {" "}
            <Typography id="modal-modal-description" sx={typographyStyles}>
              The information provided on this website, including airport
              details and routes, is for general informational purposes only.
              While we strive to keep the information up to date and correct, we
              make no representations or warranties of any kind, express or
              implied, about:
            </Typography>
            <List>
              <ListItem sx={listItemStyles}>
                <ListItemIcon sx={listItemIconStyles}>
                  <CircleIcon sx={circleIconStyles} />
                </ListItemIcon>
                <ListItemText
                  sx={listItemTextStyles}
                  disableTypography
                  primary="The completeness, accuracy, or reliability of the information."
                />
              </ListItem>
              <ListItem sx={listItemStyles}>
                <ListItemIcon sx={listItemIconStyles}>
                  <CircleIcon sx={circleIconStyles} />
                </ListItemIcon>
                <ListItemText
                  sx={listItemTextStyles}
                  disableTypography
                  primary="The suitability or availability of the website or the information, products, services, or related graphics contained on the website for any purpose."
                />
              </ListItem>
              <ListItem sx={listItemStyles}>
                <ListItemIcon sx={listItemIconStyles}>
                  <CircleIcon sx={circleIconStyles} />
                </ListItemIcon>
                <ListItemText
                  sx={listItemTextStyles}
                  disableTypography
                  primary="The uninterrupted, error-free, or secure operation of the website."
                />
              </ListItem>
              <ListItem sx={listItemStyles}>
                <ListItemIcon sx={listItemIconStyles}>
                  <CircleIcon sx={circleIconStyles} />
                </ListItemIcon>
                <ListItemText
                  sx={listItemTextStyles}
                  disableTypography
                  primary="The correction of any defects or errors on the website."
                />
              </ListItem>
            </List>
            <Typography id="modal-modal-description" sx={typographyStyles}>
              Any reliance you place on such information is therefore strictly
              at your own risk. We are not responsible for:
            </Typography>
            <List>
              <ListItem sx={listItemStyles}>
                <ListItemIcon sx={listItemIconStyles}>
                  <CircleIcon sx={circleIconStyles} />
                </ListItemIcon>
                <ListItemText
                  sx={listItemTextStyles}
                  disableTypography
                  primary="Any errors or omissions in the information provided."
                />
              </ListItem>
              <ListItem sx={listItemStyles}>
                <ListItemIcon sx={listItemIconStyles}>
                  <CircleIcon sx={circleIconStyles} />
                </ListItemIcon>
                <ListItemText
                  sx={listItemTextStyles}
                  disableTypography
                  primary="The results obtained from the use of this information."
                />
              </ListItem>
              <ListItem sx={listItemStyles}>
                <ListItemIcon sx={listItemIconStyles}>
                  <CircleIcon sx={circleIconStyles} />
                </ListItemIcon>
                <ListItemText
                  sx={listItemTextStyles}
                  disableTypography
                  primary="Any technical issues or temporary unavailability of the website."
                />
              </ListItem>
              <ListItem sx={listItemStyles}>
                <ListItemIcon sx={listItemIconStyles}>
                  <CircleIcon sx={circleIconStyles} />
                </ListItemIcon>
                <ListItemText
                  sx={listItemTextStyles}
                  disableTypography
                  primary="Any actions taken based on the content of this website."
                />
              </ListItem>
            </List>
            <Typography id="modal-modal-description" sx={typographyStyles}>
              All information is provided "as is" with no guarantee of
              completeness, accuracy, timeliness, or the results obtained from
              the use of this information. In no event will we be liable for any
              loss or damage, including without limitation:
            </Typography>
            <List>
              <ListItem sx={listItemStyles}>
                <ListItemIcon sx={listItemIconStyles}>
                  <CircleIcon sx={circleIconStyles} />
                </ListItemIcon>
                <ListItemText
                  sx={listItemTextStyles}
                  disableTypography
                  primary="Indirect or consequential loss or damage."
                />
              </ListItem>
              <ListItem sx={listItemStyles}>
                <ListItemIcon sx={listItemIconStyles}>
                  <CircleIcon sx={circleIconStyles} />
                </ListItemIcon>
                <ListItemText
                  sx={listItemTextStyles}
                  disableTypography
                  primary="Any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website."
                />
              </ListItem>
              <ListItem sx={listItemStyles}>
                <ListItemIcon sx={listItemIconStyles}>
                  <CircleIcon sx={circleIconStyles} />
                </ListItemIcon>
                <ListItemText
                  sx={listItemTextStyles}
                  disableTypography
                  primary="Any damages arising from the use or inability to use the website, including but not limited to viruses, system failures, or other technological malfunctions."
                />
              </ListItem>
            </List>
            <Typography id="modal-modal-description" sx={typographyStyles}>
              Through this website, you are able to link to other websites which
              are not under our control. We have no control over the nature,
              content, and availability of those sites. The inclusion of any
              links does not necessarily imply a recommendation or endorsement
              of the views expressed within them. We are not responsible for:
            </Typography>
            <List>
              <ListItem sx={listItemStyles}>
                <ListItemIcon sx={listItemIconStyles}>
                  <CircleIcon sx={circleIconStyles} />
                </ListItemIcon>
                <ListItemText
                  sx={listItemTextStyles}
                  disableTypography
                  primary="The content, privacy policies, or practices of any third-party websites."
                />
              </ListItem>
              <ListItem sx={listItemStyles}>
                <ListItemIcon sx={listItemIconStyles}>
                  <CircleIcon sx={circleIconStyles} />
                </ListItemIcon>
                <ListItemText
                  sx={listItemTextStyles}
                  disableTypography
                  primary="Any consequences arising from visiting or using third-party websites linked from this website."
                />
              </ListItem>
              <ListItem sx={listItemStyles}>
                <ListItemIcon sx={listItemIconStyles}>
                  <CircleIcon sx={circleIconStyles} />
                </ListItemIcon>
                <ListItemText
                  sx={listItemTextStyles}
                  disableTypography
                  primary="The accuracy or reliability of the information provided on third-party websites."
                />
              </ListItem>
            </List>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

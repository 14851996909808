import { useState, useEffect, useRef } from "react";


//This component returns a paragraph element that truncates text that exceeds the max width in pixels,
// and adds three trailing dots
export function TruncatedText({ text, maxWidthPx, elementClass }) {
  const [displayText, setDisplayText] = useState(text);
  const textRef = useRef(null);

  useEffect(() => {
    const computeDisplayText = () => {
      if (textRef.current) {
        // Ensure the full text is set before measuring
        textRef.current.textContent = text;

        const elementWidth = textRef.current.offsetWidth;

        if (elementWidth > maxWidthPx) {
          // Begin truncation process
          let truncatedText = text;
          while (
            textRef.current.offsetWidth > maxWidthPx &&
            truncatedText.length > 0
          ) {
            truncatedText = truncatedText.slice(0, -1);
            textRef.current.textContent = truncatedText + "...";
          }
          setDisplayText(truncatedText + "...");
        } else {
          // No truncation needed, set full text
          setDisplayText(text);
        }
      }
    };

    computeDisplayText();
  }, [text, maxWidthPx]); // Include maxWidthPx to recompute if the width changes

  return (
    <p {...(elementClass ? { className: elementClass } : {})} ref={textRef}>
      {displayText}
    </p>
  );
}

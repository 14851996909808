const DB_NAME = "Custom_Airports";
const DB_VERSION = 1;
const STORE_NAME = "Airports";
const mainId = "CSTM-AP-";

function openDb() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onerror = (event) => {
      console.error("Database error: ", event.target.errorCode);
      reject("Database error: " + event.target.errorCode);
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: "id" });
      }
    };
  });
}

async function getMaxID(db) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], "readonly");
    const store = transaction.objectStore(STORE_NAME);
    const keysRequest = store.getAllKeys();

    keysRequest.onsuccess = () => {
      const ids = keysRequest.result.map((id) =>
        parseInt(id.replace(mainId, ""))
      ); // Assuming IDs like 'id1', 'id2', etc.
      const maxId = ids.length === 0 ? 0 : Math.max(...ids);
      resolve(maxId + 1); // Increment the highest ID to get a new unique ID
    };

    keysRequest.onerror = (event) => {
      reject("Error fetching keys: " + event.target.errorCode);
    };
  });
}

async function getMaxAirportRef(db) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], "readonly");
    const store = transaction.objectStore(STORE_NAME);
    const request = store.getAll(); // Retrieve all records

    request.onsuccess = () => {
      // Check all records and find the highest airportRef
      const maxRef = request.result.reduce((max, record) => {
        if (record.airport && record.airport.airportRef) {
          return Math.max(max, record.airport.airportRef);
        }
        return max;
      }, 100000000); // Start with a hundred million if there are no records
      resolve(maxRef + 1); // Increment the highest ref to get a new unique ref
    };

    request.onerror = (event) => {
      reject("Error fetching records: " + event.target.errorCode);
    };
  });
}

async function addNewAirport(db, id, airportData) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], "readwrite");
    const store = transaction.objectStore(STORE_NAME);
    const data = { id: id, ...airportData }; // Include the new ID in the data
    const request = store.add(data);

    request.onsuccess = () => {
      resolve(`Airport added with ID ${id}`);
    };

    request.onerror = (event) => {
      reject("Error adding airport: " + event.target.errorCode);
    };
  });
}

async function getAllAirports(db) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], "readonly");
    const store = transaction.objectStore(STORE_NAME);
    const request = store.getAll(); // This retrieves all records from the store

    request.onsuccess = () => {
      resolve(request.result); // Returns an array of all airport records
    };

    request.onerror = (event) => {
      reject("Error fetching all airports: " + event.target.errorCode);
    };
  });
}

export async function deleteAirportAndUpdateRoutes(airportId) {
  let db = await openDb();
  const allAirports = await getAllAirports(db);

  const transaction = db.transaction([STORE_NAME], "readwrite");
  const store = transaction.objectStore(STORE_NAME);

  // Update each airport to remove routes involving the deleted airport
  for (const airport of allAirports) {
    if (airport.routes && airport.routes.hasOwnProperty(airportId)) {
      delete airport.routes[airportId];
      const updateRequest = store.put(airport);
      await new Promise((resolve, reject) => {
        updateRequest.onsuccess = resolve;
        updateRequest.onerror = () =>
          reject("Failed to update airport: " + updateRequest.error);
      });
    }
  }

  // Finally, delete the airport
  return new Promise((resolve, reject) => {
    const deleteRequest = store.delete(airportId);
    deleteRequest.onsuccess = () => resolve("Airport deleted successfully");
    deleteRequest.onerror = (event) =>
      reject("Error deleting airport: " + event.target.errorCode);
  });
}

export async function getStoredCustomAirports() {
  let db = await openDb();
  const allCurrentAirportRecords = await getAllAirports(db);
  return allCurrentAirportRecords;
}

//if no name is given, generate a generic name using the uniqueId

export async function addCustomAirportToIndexedDB(
  name,
  description,
  coordinates,
  airportsInRange
) {
  let db;
  try {
    db = await openDb();
    const uniqueId = await getMaxID(db);
    const newAirportId = mainId + uniqueId;
    const newAirportRef = await getMaxAirportRef(db);

    //if no name is given, generate a generic name using the uniqueId
    let airportName = name ? name : `Custom Airport ${uniqueId}`;

    const newAirportObject = {
      airportRef: newAirportRef,
      airportId: newAirportId,
      name: airportName,
      location: {
        coordinates: [coordinates.lon, coordinates.lat],
      },
      description: description,
      country: { name: "Custom Airport" },
      type: "custom",
      custom: true,
    };

    let currentAirportsArray = await getAllAirports(db);
    const combinedAirportsArray = airportsInRange.concat(
      currentAirportsArray.map((record) => record.airport)
    );
    const newDBRecord = {
      airport: newAirportObject,
      routes: calculateRoutesForAirport(
        newAirportObject,
        combinedAirportsArray
      ),
    };

    await addNewAirport(db, newAirportId, newDBRecord);
  } catch (error) {
    console.error("Error adding custom airport to DB:", error);
    // Handle the error, maybe retry or inform the user
  }
}

// Used to edit custom airport name and description
export async function editCustomAirport(id, name, description) {
  let db = await openDb();
  const transaction = db.transaction([STORE_NAME], "readwrite");
  const store = transaction.objectStore(STORE_NAME);
  const request = store.get(id);

  request.onsuccess = function () {
    const updatingData = request.result;
    updatingData.airport.name = name;
    updatingData.airport.description = description;
    // Update other fields as necessary

    const updateRequest = store.put(updatingData);
    updateRequest.onsuccess = function () {
      console.log("Airport updated successfully");
    };
  };

  request.onerror = function (event) {
    console.error("Error updating airport:", event.target.errorCode);
  };
}

//Utility Functions
// function to calculate the distance between two coordinates using the Haversine formula
function calculateDistance(lat1, lon1, lat2, lon2) {
  const R = 6371; // radius of the Earth in km
  const dLat = ((lat2 - lat1) * Math.PI) / 180; // delta latitude in radians
  const dLon = ((lon2 - lon1) * Math.PI) / 180; // delta longitude in radians
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return (R * c).toFixed(2); // distance in km
}

// function to generate a unique route ID based on start and end points
function generateRouteId(start, end) {
  const points = [start, end];
  points.sort(); // ensures consistency in ID regardless of order
  return points.join("_"); // concatenates points with an underscore
}

// function to generate routes and calculate distance between airports
function calculateRoutesForAirport(airport, airportsWithinDistance) {
  let routes = new Map();
  let custom = false;
  if (airport.custom) {
    custom = true;
  }

  airportsWithinDistance.forEach((destination) => {
    const routeId = generateRouteId(airport.airportId, destination.airportId);
    // check to avoid duplicate routes and self-routes
    if (!routes.has(routeId) && airport.airportId !== destination.airportId) {
      const distance = calculateDistance(
        airport.location.coordinates[1], // latitude
        airport.location.coordinates[0], //longitude
        destination.location.coordinates[1], // latitude
        destination.location.coordinates[0] //longitude
      );

      //Set to avoid duplicate countries/regions
      let countriesSet = new Set();
      if (airport.country && airport.country.iso) {
        countriesSet.add(airport.country.iso);
      }

      if (destination.country && destination.country.iso) {
        countriesSet.add(destination.country.iso);
      }

      // creating the route object
      let routeObject = {
        _id: routeId,
        airport_1: airport.airportId,
        airport_2: destination.airportId,
        lonlat_1: [
          Number(airport.location.coordinates[0]),
          Number(airport.location.coordinates[1]),
        ],
        lonlat_2: [
          Number(destination.location.coordinates[0]),
          Number(destination.location.coordinates[1]),
        ],
        distance: Number(distance),
        countries: Array.from(countriesSet),
        type: "custom",
      };

      if (routeObject.distance <= 400) {
        // store the route
        routes.set(routeId, routeObject);
      }
    }
  });

  return Array.from(routes.values()); // returns all generated route objects in an array
}

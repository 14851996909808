/**
 * Creates an advanced marker SVG element based on the provided options.
 *
 * @param {Object} options - Configuration options for the SVG marker.
 * @param {string} options.airportType - Type of the airport used to determine size and color.
 * @param {string} [options.color] - Fill color for the marker. Defaults to the color associated with the airport type.
 * @param {number} [options.opacity=1] - Opacity of the fill color.
 * @param {string} [options.strokeColor="#000"] - Stroke color for the marker's border.
 * @param {number} [options.strokeWidth=1.2] - Width of the stroke on the marker's border.
 * @param {Function} getIconSize - A function that returns the size multiplier based on the airport type.
 * @param {Object} markerColors - An object mapping airport types to marker colors.
 *
 * @returns {SVGElement} The generated SVG element for the marker.
 */
export const createAdvancedMarkerSvg = (options, getIconSize, markerColors) => {
  const {
    airportType,
    color = markerColors[airportType],
    opacity = 1,
    strokeColor = "#000",
    strokeWidth = 1.2,
  } = options;

  // Get the size multiplier based on the airport type
  const sizeMultiplier = getIconSize(airportType);

  // Calculate the dimensions based on the size multiplier and zoom level
  const width = 14 * sizeMultiplier;
  const height = 14 * sizeMultiplier;

  const svgContent = `<svg class="advancedIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}" width="${width}" height="${height}">
			  <circle cx="${width / 2}" cy="${height / 2}" r="${
    width / 2 - 1
  }" fill="${color}" fill-opacity="${opacity}" stroke="${strokeColor}" stroke-width="${strokeWidth}"/>
		  </svg>`;

  const parser = new DOMParser();
  return parser.parseFromString(svgContent, "image/svg+xml").documentElement;
};

import { useState, useEffect, useRef } from "react";
import { useFilterContext } from "./ContextManager";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { styled } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterTooltip from "./UX/FilterTooltip/FilterTooltip";

// Define profiles with specific settings
const planeProfiles = {
  default: {
    minRunwayLength: 750,
    maxRunwayLength: 6000,
    maxDistance: 200,
    speed: 250,
  },
  elflyNoemi: {
    minRunwayLength: 750,
    maxRunwayLength: 6000,
    maxDistance: 200,
    speed: 250,
  },
  heartES30: {
    minRunwayLength: 1100,
    maxRunwayLength: 6000,
    maxDistance: 400,
    speed: 350,
  },
};

// Styled FormControl for a smaller display
const SmallFormControl = styled(FormControl)({
  minWidth: 100,
  fontSize: "0.75rem", // Smaller font for FormControl
  "& .MuiInputLabel-root": {
    fontSize: "0.75rem", // Smaller font for label
  },
  "& .MuiSelect-root": {
    fontSize: "0.75rem", // Smaller font for selected text
    padding: "4px 8px", // Adjust padding to reduce height
  },
  "& .MuiMenuItem-root": {
    fontSize: "0.75rem", // Smaller font for dropdown items
    padding: "4px 8px", // Adjust padding to reduce item height
  },
});

export function PlaneProfiles() {
  const { filters, updateFilters, selectedProfile, updateSelectedProfile } = useFilterContext();
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    if (selectedProfile === "default") {
      setIsModified(false);
      return;
    }

    const originalSettings = planeProfiles[selectedProfile];
    const hasModifications =
      filters.minRunwayLength !== originalSettings.minRunwayLength ||
      filters.maxRunwayLength !== originalSettings.maxRunwayLength ||
      filters.maxDistance !== originalSettings.maxDistance ||
      filters.speed !== originalSettings.speed;

    setIsModified(hasModifications);
  }, [filters.minRunwayLength, filters.maxRunwayLength, filters.maxDistance, filters.speed, selectedProfile]);

  const handleProfileChange = (event) => {
    const newProfile = event.target.value;
    updateSelectedProfile(newProfile);
    const profileSettings = planeProfiles[newProfile];
    updateFilters(prev => ({...prev, ...profileSettings}));
  };

  // Only show modified in the label, not in dropdown
  const getProfileName = (profileId) => {
    const names = {
      default: "Default",
      elflyNoemi: "Elfly Noemi",
      heartES30: "Heart ES-30"
    };
    return names[profileId];
  };

  const getDisplayValue = () => {
    const baseName = getProfileName(selectedProfile);
    return selectedProfile !== "default" && isModified ? `${baseName} - Modified` : baseName;
  };

  return (
    <SmallFormControl size="small">
      <InputLabel>Aircraft</InputLabel>
      <Select
        value={selectedProfile}
        onChange={handleProfileChange}
        label="Aircraft"
        size="small"
        IconComponent={ExpandMoreIcon}
        renderValue={getDisplayValue}
      >
        <MenuItem
          value="default"
          onClick={() => {
            if (selectedProfile === "default") {
              const profileSettings = planeProfiles.default;
              updateFilters(prev => ({...prev, ...profileSettings}));
            }
          }}
        >
          <FilterTooltip id="planeProfile" airport="default">
            <div style={{ width: "100%" }}>{getProfileName("default")}</div>
          </FilterTooltip>
        </MenuItem>
        <MenuItem
          value="elflyNoemi"
          onClick={() => {
            if (selectedProfile === "elflyNoemi") {
              const profileSettings = planeProfiles.elflyNoemi;
              updateFilters(prev => ({...prev, ...profileSettings}));
            }
          }}
        >
          <FilterTooltip id="planeProfile" airport="elflyNoemi">
            <div style={{ width: "100%" }}>{getProfileName("elflyNoemi")}</div>
          </FilterTooltip>
        </MenuItem>
        <MenuItem
          value="heartES30"
          onClick={() => {
            if (selectedProfile === "heartES30") {
              const profileSettings = planeProfiles.heartES30;
              updateFilters(prev => ({...prev, ...profileSettings}));
            }
          }}
        >
          <FilterTooltip id="planeProfile" airport="heartES30">
            <div style={{ width: "100%" }}>{getProfileName("heartES30")}</div>
          </FilterTooltip>
        </MenuItem>
      </Select>
    </SmallFormControl>
  );
}

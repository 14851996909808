import { useState, useEffect, useRef } from "react";
import style from "../../../css/mapMarkerTooltip.module.css";

export function MapMarkerTooltip({ markerData }) {
  const tooltipRef = useRef(null);
  const [tooltipWidth, setTooltipWidth] = useState(0);

  useEffect(() => {
    if (tooltipRef.current) {
      setTooltipWidth(tooltipRef.current.offsetWidth / 2);
    }
  }, [markerData]);

  if (!markerData || !markerData.airportData) {
    return null;
  }

  const finalStyle = {
    position: "absolute",
    zIndex: 100,
    bottom: markerData.pixelPointKey.y + 15,
    left: markerData.pixelPointKey.x - tooltipWidth,
    filter: "drop-shadow(0.3rem 0.3rem 0.5rem hsla(0, 0%, 13%, 0.7))",
  };

  const { airportData } = markerData;

  return (
    <div ref={tooltipRef} style={finalStyle} className={style.scaleUpBottom}>
      <div className={style.tooltip}>
        <p className={style.name}>{airportData.name}</p>
        {airportData.municipality && airportData.country?.name ? (
          <p className={style.location}>
            {airportData.municipality}, {airportData.country.name}
          </p>
        ) : airportData.type !== "custom" && airportData.country?.name ? (
          <p className={style.location}>{airportData.country.name}</p>
        ) : null}
        {airportData.type && (
          <p className={style.type}>
            {airportData.type === "seaplane" ? "seaplane Base" : `${airportData.type} Airport`}
          </p>
        )}
      </div>
      <div className={style.arrow}></div>
    </div>
  );
}

import { useState, useEffect } from "react";
import style from "../../../css/dataView.module.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";

export function CustomPagination({
  rowsPerPage,
  totalRows,
  currentPage,
  onPageChange,
}) {
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const [inputPage, setInputPage] = useState(currentPage + 1);

  // Sync input field with current page when it changes externally
  useEffect(() => {
    setInputPage(currentPage + 1);
  }, [currentPage]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputPage(value);

    let pageNumber = parseInt(value, 10);
    if (!isNaN(pageNumber)) {
      pageNumber = Math.min(Math.max(pageNumber, 1), totalPages); // Clamp to [1, totalPages]
      onPageChange(pageNumber - 1);
    }
  };

  const handleInputBlur = () => {
    let pageNumber = parseInt(inputPage, 10);
    if (isNaN(pageNumber) || pageNumber < 1) {
      setInputPage(currentPage + 1); // Reset to current page if input is invalid or empty
    } else if (pageNumber > totalPages) {
      setInputPage(totalPages); // Reset to last page if input exceeds total pages
    }
  };

  return (
    <div className={style.customPagination}>
      <div className={style.paginationNav}>
        <button
          onClick={() => onPageChange(0)}
          disabled={currentPage === 0}
          className={style.paginationButton}
        >
          <FirstPageIcon
            className={
              currentPage === 0 ? style.iconDisabled : style.paginationIconColor
            }
          />
        </button>
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 0}
          className={style.paginationButton}
        >
          <ChevronLeftIcon
            className={
              currentPage === 0 ? style.iconDisabled : style.paginationIconColor
            }
          />
        </button>
        <div className={style.pageInputWrapper}>
          Page{" "}
          <input
            type="text"
            value={inputPage}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            className={style.pageInput}
            placeholder={`${currentPage + 1}`}
          />{" "}
          of {totalPages}
        </div>
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages - 1}
          className={style.paginationButton}
        >
          <ChevronRightIcon
            className={
              currentPage === totalPages - 1
                ? style.iconDisabled
                : style.paginationIconColor
            }
          />
        </button>
        <button
          onClick={() => onPageChange(totalPages - 1)}
          disabled={currentPage === totalPages - 1}
          className={style.paginationButton}
        >
          <LastPageIcon
            className={
              currentPage === totalPages - 1
                ? style.iconDisabled
                : style.paginationIconColor
            }
          />
        </button>
      </div>

      <div>
        {currentPage * rowsPerPage + 1}-
        {Math.min((currentPage + 1) * rowsPerPage, totalRows)} of {totalRows}
      </div>
    </div>
  );
}

import { SignIn } from "@clerk/clerk-react";

export function CustomSignIn() {
  return (
    <div className="custom-sign-in-container">
      <SignIn
        localization={{
          signIn: {
            start: {
              title: "Welcome to NEA Vision Tool",
              subtitle: "Sign in to access your account",
              buttonText: "Sign In with Email",
            },
          },
        }}
      />
    </div>
  );
}

import { useState, useEffect, useCallback, useRef } from "react";
// CSS
import style from "../css/layout.module.css";
// icons
import chevronLeft from "../assets/svg/chevron_left.svg";

import { mergeFiltersWithDefaults } from "../utils/mergeFiltersWithDefaults";
import { decompressObjectFromURL } from "../data/schemas/filterCodes";

//Clerk
import { SignedIn, SignedOut } from "@clerk/clerk-react";

import { useFilterContext } from "./ContextManager";
import { MapComponent } from "./MapComponent";
import { Filter } from "./Filter";
import { Modal } from "./Modal";
import { RouteModal } from "./RouteModal";
import { LoadingSpinnerModal } from "./LoadingSpinnerModal";
import { LoadingScreen } from "./LoadingScreen";
import { DataTable } from "./DataTable";
import { CustomSignIn } from "./CustomSignIn";

export function Layout() {
  const [map, setMap] = useState(null);
  const [isTableOpen, setIsTableOpen] = useState(false);
  const [tableWidth, setTableWidth] = useState("0px"); // Initially closed
  const [lastManualWidth, setLastManualWidth] = useState("40%"); // Track last set width
  const [resizing, setResizing] = useState(false);
  const [tableButtonClass, setTableButtonClass] = useState(
    style.dataTableButton
  );
  const dataTableRef = useRef(null);
  const contentRef = useRef(null); // Reference for content inside DataTable

  const handleTableButtonClick = () => {
    setIsTableOpen((prev) => {
      const newState = !prev;
      if (newState) {
        animateTableOpen();
        setTableButtonClass(
          `${style.dataTableButton} ${style.dataTableButtonOpen}`
        );
      } else {
        animateTableClose();
        setTableButtonClass(style.dataTableButton);
      }
      return newState;
    });
  };

  const animateTableOpen = () => {
    const dataTable = dataTableRef.current;
    dataTable.style.transition = "width 1s ease, box-shadow 1s ease";
    dataTable.style.width = lastManualWidth;
    dataTable.style.boxShadow = "-0.3rem 0 0.3rem rgba(42, 42, 42, 0.45)";

    // Add the fade-in class to the content
    setTimeout(() => {
      contentRef.current.classList.add(style.fadeIn);
      contentRef.current.classList.remove(style.fadeOut);
    }, 1000); // Delay to match table opening
  };

  const animateTableClose = () => {
    const dataTable = dataTableRef.current;

    // Add the fade-out class to the content first
    contentRef.current.classList.remove(style.fadeIn);
    contentRef.current.classList.add(style.fadeOut);

    // Delay the table close animation until after the fade-out completes (250ms)
    setTimeout(() => {
      dataTable.style.transition = "width 1s ease, box-shadow 1s ease";
      dataTable.style.width = "0px";
      dataTable.style.boxShadow = "0 0 0 rgba(42, 42, 42, 0)";
    }, 250);
  };

  const handleMouseDown = useCallback((e) => {
    setResizing(true);
    const dataTable = dataTableRef.current;
    dataTable.style.transition = "none"; // Stop any animations during resizing
    e.preventDefault();
  }, []);

  const handleMouseMove = useCallback(
    (e) => {
      if (resizing) {
        const maxWidth = window.innerWidth - 336; // Convert 21rem to pixels
        const newWidth = window.innerWidth - e.clientX;
        const clampedWidth = Math.min(Math.max(350, newWidth), maxWidth);

        // Update the width dynamically during resize
        setTableWidth(`${clampedWidth}px`);
        setLastManualWidth(`${clampedWidth}px`);
        dataTableRef.current.style.width = `${clampedWidth}px`;
      }
    },
    [resizing]
  );

  const handleMouseUp = useCallback(() => {
    setResizing(false);
  }, []);

  useEffect(() => {
    if (resizing) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [resizing, handleMouseMove, handleMouseUp]);

  const updateMap = (newMap) => {
    setMap(newMap);
  };

  return (
    <>
      <SignedIn>
        {" "}
        <div className={style.mapAndTableWrapper}>
          <div className={style.mapContainer}>
            <Filter map={map} />
            <Modal />
            <RouteModal />
            <MapComponent map={map} updateMap={updateMap} />
            <div className={tableButtonClass} onClick={handleTableButtonClick}>
              <img
                src={chevronLeft}
                alt="Chevron"
                className={
                  isTableOpen
                    ? `${style.chevron} ${style.chevronOpen}`
                    : `${style.chevron} ${style.chevronClose}`
                }
              />
            </div>
          </div>
          <div
            className={style.dataTable}
            ref={dataTableRef}
            style={{ width: tableWidth }}
          >
            <div className={style.resizer} onMouseDown={handleMouseDown}></div>
            <div
              ref={contentRef}
              className={`${style.hiddenContent} ${
                isTableOpen ? style.fadeIn : style.fadeOut
              } ${isTableOpen ? style.displayBlock : style.hidden}`}
            >
              <DataTable />
            </div>
          </div>
        </div>
        <LoadingSpinnerModal />
        <LoadingScreen />
      </SignedIn>
      <SignedOut>
        {/* <SignInButton /> */}
        {/* <LoginForm updateLoginStatus={updateLoginStatus} /> */}
        <div className={style.signInPage}>
          <CustomSignIn />
        </div>
      </SignedOut>
    </>
  );
}

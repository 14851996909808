import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import styled from "../css/disclaimerFeedbackModal.module.css";

export const SuccessMessage = ({ open, handleClose }) => (
  <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box className={styled.modal}>
      <div className={styled.header}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Successfully submitted
        </Typography>
        <IconButton
          className={styled.closeButton}
          aria-label="close menu"
          size="small"
          onClick={handleClose}
        >
          <CloseIcon style={{ color: "rgba(125, 125, 125, 0.9)" }} />
        </IconButton>
      </div>
      <Typography variant="body1" className={styled.successMessage}>
        Thank you for your feedback.
      </Typography>
    </Box>
  </Modal>
);

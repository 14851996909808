const chartColors = [
  "#0B486C", // Slate-NEA
  "#20BF55", // Green-NEA
  "#01BAEF", // Blue-NEA
  "#058aff",
  "#dc9e03",
  "#7d00ea",
  "#ff6600",
  "#008552",
];

// Bar Chart Constructor
export const createBarChart = ({ title, categories, seriesData }) => ({
  title,
  type: "bar",
  options: {
    chart: {
      type: "bar",
      stacked: false, // Explicitly set to false
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: false, // Explicitly set to false
        columnWidth: "70%", // Default value
        distributed: true,
        dataLabels: {
          position: "top",
          total: {
            enabled: false, // Explicitly set to false
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => val,
      offsetY: -20,
      offsetX: 0, // Default value
      style: {
        fontSize: "12px",
        colors: ["#304758"],
        textShadow: undefined, // Default value
      },
    },
    xaxis: {
      categories,
      labels: {
        rotate: -45,
      },
    },
    colors: chartColors,
    legend: {
      show: false,
      position: "bottom", // Default value
      horizontalAlign: "center", // Default value
      fontSize: "12px", // Default value
      labels: {
        colors: "#333", // Default value
      },
    },
    tooltip: {
      y: {
        formatter: undefined, // Default value
      },
    },
    labels: [], // Default value
  },
  series: [
    {
      name: "Count",
      data: seriesData,
    },
  ],
});

// Grouped Bar Chart Constructor
export const createGroupedBarChart = ({ title, categories, seriesData }) => ({
  title,
  type: "bar",
  options: {
    chart: {
      type: "bar",
      stacked: false, // Explicitly set to false
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%", // Adjusted for grouped bars
        distributed: false, // Explicitly set to false
        dataLabels: {
          position: "top", // Default value
          total: {
            enabled: false, // Explicitly set to false
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => val,
      offsetY: -20,
      offsetX: 0, // Default value
      style: {
        fontSize: "12px",
        colors: ["#304758"],
        textShadow: undefined, // Default value
      },
    },
    xaxis: {
      categories,
      labels: {
        rotate: -45,
      },
    },
    colors: chartColors,
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "center", // Default value
      fontSize: "12px", // Default value
      labels: {
        colors: "#333", // Default value
      },
    },
    tooltip: {
      y: {
        formatter: undefined, // Default value
      },
    },
    labels: [], // Default value
  },
  series: seriesData,
});

// Radial Bar Chart Constructor
export const createRadialBarChart = ({ title, labels, seriesData }) => ({
  title,
  type: "radialBar",
  options: {
    chart: {
      type: "radialBar",
      stacked: false, // Explicitly set to false
      toolbar: { show: false }, // Explicitly hide toolbar
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "60%",
        },
        dataLabels: {
          name: {
            show: true,
          },
          value: {
            show: true,
            formatter: (val) => `${val}%`,
          },
        },
      },
    },
    dataLabels: {
      enabled: true, // Default value
      formatter: undefined, // Default value
      offsetY: 0, // Default value
      offsetX: 0, // Default value
      style: {
        fontSize: "12px", // Default value
        colors: undefined, // Default value
        textShadow: undefined, // Default value
      },
    },
    xaxis: {
      categories: [], // Default value
      labels: {
        rotate: 0, // Default value
      },
    },
    colors: chartColors,
    legend: {
      show: true, // Default value
      position: "bottom",
      horizontalAlign: "center",
      fontSize: "12px", // Default value
      labels: {
        colors: "#333", // Default value
      },
    },
    tooltip: {
      y: {
        formatter: undefined, // Default value
      },
    },
    labels,
  },
  series: seriesData,
});

// Donut Chart Constructor
export const createDonutChart = ({ title, labels, seriesData }) => ({
  title,
  type: "donut",
  options: {
    chart: {
      type: "donut",
      stacked: false, // Explicitly set to false
      toolbar: { show: false }, // Explicitly hide toolbar
    },
    plotOptions: {
      pie: {
        startAngle: 0, // Default value
        endAngle: 360, // Default value
        donut: {
          size: "65%", // Default size
          background: undefined, // Default value
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val.toFixed(1)}%`,
      offsetY: 0, // Default value
      offsetX: 0, // Default value
      style: {
        fontSize: "12px", // Default value
        colors: undefined, // Default value
        textShadow: undefined, // Default value
      },
    },
    xaxis: {
      categories: [], // Default value
      labels: {
        rotate: 0, // Default value
      },
    },
    colors: chartColors,
    legend: {
      show: true, // Default value
      position: "bottom",
      horizontalAlign: "center",
      fontSize: "12px", // Default value
      labels: {
        colors: "#333", // Default value
      },
    },
    tooltip: {
      y: {
        formatter: (val) => `${val} Airports`,
      },
    },
    labels,
  },
  series: seriesData,
});

// Semi-Donut Chart Constructor
export const createSemiDonutChart = ({ title, labels, seriesData }) => ({
  title,
  type: "donut",
  options: {
    chart: {
      type: "donut",
      stacked: false, // Explicitly set to false
      toolbar: { show: false }, // Explicitly hide toolbar
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        donut: {
          size: "60%", // Adjust size as needed
          background: "transparent",
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val.toFixed(1)}%`,
      offsetY: 0, // Default value
      offsetX: 0, // Default value
      style: {
        fontSize: "12px", // Default value
        colors: undefined, // Default value
        textShadow: undefined, // Default value
      },
    },
    xaxis: {
      categories: [], // Default value
      labels: {
        rotate: 0, // Default value
      },
    },
    colors: chartColors,
    legend: {
      show: true, // Default value
      position: "bottom",
      horizontalAlign: "center",
      fontSize: "12px", // Default value
      labels: {
        colors: "#333", // Default value
      },
    },
    tooltip: {
      y: {
        formatter: (val) => `${val} Airports`,
      },
    },
    labels,
  },
  series: seriesData,
});

// Pie Chart Constructor
export const createPieChart = ({ title, labels, seriesData }) => ({
  title,
  type: "pie",
  options: {
    chart: {
      type: "pie",
      stacked: false, // Explicitly set to false
      toolbar: { show: false }, // Explicitly hide toolbar
    },
    plotOptions: {
      pie: {
        startAngle: 0, // Default value
        endAngle: 360, // Default value
        donut: {
          size: "0%", // No donut hole
          background: undefined, // Default value
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val.toFixed(1)}%`,
      offsetY: 0, // Default value
      offsetX: 0, // Default value
      style: {
        fontSize: "12px", // Default value
        colors: undefined, // Default value
        textShadow: undefined, // Default value
      },
    },
    xaxis: {
      categories: [], // Default value
      labels: {
        rotate: 0, // Default value
      },
    },
    colors: chartColors,
    legend: {
      show: true, // Default value
      position: "right",
      horizontalAlign: "center",
      fontSize: "12px", // Default value
      labels: {
        colors: "#333", // Default value
      },
    },
    tooltip: {
      y: {
        formatter: (val) => `${val} Airports`,
      },
    },
    labels,
  },
  series: seriesData,
});

// Stacked Bar Chart Constructor
export const createStackedBarChart = ({ title, categories, seriesData }) => ({
  title,
  type: "bar",
  options: {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "70%", // Default value
        distributed: false, // Explicitly set to false
        dataLabels: {
          position: "center", // Position labels inside each stack
          total: {
            enabled: true,
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
      formatter: (val) => val,
      offsetY: 1,
      offsetX: 0,
      style: {
        fontSize: "8px",
        colors: ["#ffffff"],
        textShadow: "0px 0px 3px rgba(0, 0, 0, 0.7)",
      },
    },
    xaxis: {
      categories,
      labels: { rotate: -45 },
    },
    colors: chartColors.slice(0, seriesData.length),
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "center",
      fontSize: "12px",
      labels: {
        colors: "#333",
      },
    },
    tooltip: {
      y: {
        formatter: undefined, // Default value
      },
    },
    labels: [], // Default value
  },
  series: seriesData,
});

import { createTheme } from "@mui/material/styles";
import * as variables from "../utils/variables";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//Theme for mui components

const muiTheme = createTheme({
  components: {
    MuiSwitch: {
      defaultProps: {
        size: "small", // Apply 'small' size by default to all Switch components
      },
      styleOverrides: {
        switchBase: {
          // Custom colors for the switch component when it is checked
          "&.Mui-checked": {
            color: "var(--nea-nightblue-color)",
            "&:hover": {
              backgroundColor: "rgba(0, 32, 91, 0.08)"
            },
            "& + .MuiSwitch-track": {
              backgroundColor: "var(--nea-nightblue-color)"
            },
            "&.Mui-disabled": {
              color: "#DDDDDD",
              "& + .MuiSwitch-track": {
                backgroundColor: "#DDDDDD"
              }
            }
          },
          "&.Mui-disabled": {
            color: "#DDDDDD",
            "& + .MuiSwitch-track": {
              backgroundColor: "#DDDDDD"
            }
          }
        },
        track: {
          backgroundColor: "rgba(0, 0, 0, 0.38)",  // Default unchecked color
          "&.Mui-disabled": {
            backgroundColor: "#DDDDDD"
          }
        }
      },
    },
    MuiSlider: {
      defaultProps: {
        size: "small", // Set a default size for all Slider components, if 'small' is a valid option
      },
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            "& .MuiSlider-rail": {
              backgroundColor: "#DDDDDD !important"
            }
          }
        },
        thumb: {
          backgroundColor: "var(--nea-nightblue-color)",
          "&:hover": {
            boxShadow: "0 0 0 8px rgba(0, 32, 91, 0.16)"
          },
          "&.Mui-disabled": {
            backgroundColor: "#DDDDDD"
          }
        },
        track: {
          backgroundColor: "var(--nea-nightblue-color)",
          "&.Mui-disabled": {
            backgroundColor: "#DDDDDD !important"
          }
        },
        rail: {
          backgroundColor: "var(--nea-nightblue-color)",
          opacity: 0.3,
          "&.Mui-disabled": {
            backgroundColor: "#DDDDDD !important",
            opacity: 1
          }
        },
        valueLabel: {
          backgroundColor: "var(--nea-nightblue-color)"
        }
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // Apply the main color as the background color for the primary button variant
          color: "var(--nea-white-color)", // NEA white color
          backgroundColor: "var(--nea-nightblue-color)", //NEA nightblue color
          "&:hover": {
            backgroundColor: "var(--nea-clearblue-color)", //NEA clearblue color
            opacity: 0.9, // Slightly lower opacity on hover
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        primary: {
          backgroundColor: "var(--nea-nightblue-color)", //NEA nightblue color
          "&:hover": {
            backgroundColor: "var(--nea-clearblue-color)", //NEA clearblue color
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: '"Jaldi", sans-serif',
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--nea-nightblue-color)", // Add hover border color
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--nea-nightblue-color)",
          },
          "& input[type=number]": {
            MozAppearance: "textfield",
            "&::-webkit-outer-spin-button": {
              WebkitAppearance: "none",
              margin: 0,
            },
            "&::-webkit-inner-spin-button": {
              WebkitAppearance: "none",
              margin: 0,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: '"Jaldi", sans-serif',
          fontWeight: 400,
          "&.MuiFormLabel-root": {
            fontSize: "1rem",
          },
          "&.Mui-focused": {
            color: "var(--nea-nightblue-color)",
          },
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&:before": {
            display: "none", // Remove the default divider
          },
          "&:hover, &.Mui-expanded": {
            boxShadow: "0px 2px 9px rgba(0, 0, 0, 0.3)",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&.Mui-expanded": {
            minHeight: "48px",
          },
        },
        content: {
          "&.Mui-expanded": {
            margin: "0px 0",
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: variables.mainColorDark, // Default color for the slider track
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)", // Add box shadow
          backgroundColor: variables.mainColorDark,
          fontSize: "0.8rem", // Customize the background color and transparency here
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          color: "#fff", // Text color for inactive tabs
        },
        indicator: {
          backgroundColor: variables.mainColorDark, // Color for the active tab indicator
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: variables.mainColorDark, // Text color for the active tab
          },
          textTransform: "none", // Disable uppercase transformation
        },
        wrapper: {
          flexDirection: "row", // Align icon and label horizontally
        },
        icon: {
          fontSize: "1.5rem", // Change the size of the tab icon
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        filled: {
          "&.MuiChip-filledDefault": {
            backgroundColor: "var(--nea-nightblue-color)",
            opacity: 0.88,
            height: "1.8rem",
            WebkitFontSmoothing: "antialiased",
            MozOsxFontSmoothing: "grayscale",
            "&:hover": {
              backgroundColor: "var(--nea-clearblue-color)",
            },
            "& .MuiChip-label.MuiChip-labelMedium": {
              color: "var(--nea-white-color)",
              padding: "0.5rem",
              WebkitFontSmoothing: "antialiased",
              MozOsxFontSmoothing: "grayscale",
            },
            "& .MuiChip-deleteIcon": {
              color: "var(--nea-white-color)",
              fontSize: "20px",
              margin: "0 4px 0 -4px",
              "&:hover": {
                color: "var(--nea-white-color)",
                opacity: 0.8,
              },
            },
          },
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          "& .MuiPaper-root": {
            fontFamily: '"Jaldi", sans-serif',
            "& *": {
              fontFamily: "inherit"
            },
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
            borderRadius: "8px",
            "& .MuiAutocomplete-option:hover": {
              backgroundColor: "rgba(0, 156, 222, 0.15) !important",
              color: "var(--nea-nightblue-color)"
            },
            "& .MuiAutocomplete-option[aria-selected='true']": {
              backgroundColor: "rgba(0, 156, 222, 0.15) !important",
              color: "var(--nea-nightblue-color)"
            }
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
      }
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ExpandMoreIcon
      },
      styleOverrides: {
        root: {
          fontFamily: '"Jaldi", sans-serif',
          fontWeight: 700,
          height: "48px",  // Match Accordion height
        },
        icon: {
          right: "1rem",  // Match the right padding
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: '"Jaldi", sans-serif',
          "&:hover": {
            backgroundColor: "rgba(0, 156, 222, 0.15)",
            color: "var(--nea-nightblue-color)"
          },
          "&.Mui-selected": {
            backgroundColor: "rgba(0, 156, 222, 0.15)",
            color: "var(--nea-nightblue-color)",
            "&:hover": {
              backgroundColor: "rgba(0, 156, 222, 0.15)"
            }
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiMenu-paper": {
            fontFamily: '"Jaldi", sans-serif',
            fontWeight: 700,
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
            borderRadius: "8px"
          }
        }
      }
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <ExpandMoreIcon />
      },
      styleOverrides: {
        popupIndicator: {
          right: "0.5rem",
          color: "secondary"
        }
      }
    }
  },
});

export default muiTheme;

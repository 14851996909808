import { MapStyleToggle } from "./MapStyleToggle";
import { CustomZoomControls } from "./UX/CustomZoomControls/CustomZoomControls";

export function BottomMapControls({ currentStyle, onStyleChange, map }) {
  return (
    <div>
      <MapStyleToggle
        currentStyle={currentStyle}
        onStyleChange={onStyleChange}
        map={map}
      />
      <CustomZoomControls map={map} />
    </div>
  );
}

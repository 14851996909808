//Material UI
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import style from "../css/contextMenu.module.css";
export const ContextMenu = ({
  visible,
  x,
  y,
  lat,
  lon,
  onAddCircle,
  onRemoveCircle,
  updateRadius,
  radius,
  openCustomAirportForm,
}) => {
  if (!visible) return null;

  const handleInputChange = (event) => {
    updateRadius(event.target.value);
  };

  return (
    <div
      style={{
        position: "absolute",
        top: y + 5,
        left: x + 5,
        zIndex: 5,
        backgroundColor: "#fff",
        boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
        borderRadius: "4px",
      }}
    >
      <ul style={{ listStyle: "none", padding: 10 }}>
        <Typography
          id="range-slider"
          style={{ fontWeight: 700, fontSize: "0.9rem", padding: "0px" }}
        >
          Radius (km)
        </Typography>
        <Slider
          size="small"
          step={10}
          min={0}
          max={400}
          defaultValue={radius}
          aria-label="Small"
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          onChange={handleInputChange}
        />
        <li
          className={style["context-menu-item"]}
          onClick={onAddCircle}
        >
          Add Range Circle
        </li>

        <li
          className={style["context-menu-item"]}
          onClick={onRemoveCircle}
        >
          Remove Range Circle
        </li>

        <li
          className={style["context-menu-item"]}
          onClick={openCustomAirportForm}
        >
          Add Custom Airport
        </li>
      </ul>
    </div>
  );
};

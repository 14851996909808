import { useState, useEffect, useMemo } from "react";
import { useFilterContext } from "./ContextManager";
import style from "../css/modal.module.css";
import altStyle from "../css/polylineTooltip.module.css";
import rstyle from "../css/routemodal.module.css";

import { IconButton } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";

import { PriceData } from "./PriceData";

// Helper functions
const formatTime = (minutes, applyColor = false, isSlower = false) => {
  if (minutes == null) return null;
  const absMinutes = Math.abs(minutes);
  const hours = Math.floor(absMinutes / 60);
  const mins = absMinutes % 60;
  let timeString = "";
  if (hours > 0) {
    timeString += `${hours}h `;
  }
  if (mins > 0 || hours === 0) {
    timeString += `${mins}m`;
  }
  if (applyColor) {
    const color = minutes < 0 ? "red" : "green";
    const sign = minutes < 0 ? "-" : "+";
    return (
      <span style={{ color }}>
        {sign}
        {timeString}
      </span>
    );
  } else if (isSlower) {
    return <span style={{ textDecoration: "line-through" }}>{timeString}</span>;
  } else {
    return timeString;
  }
};

/*const formatRouteSpeedComparison = (ratio) => {
  let value, color;
  const parsedRatio = parseFloat(ratio);
  if (ratio === "1.0") {
    value = "1.0x";
    color = "black";
  } else if (parsedRatio > 1) {
    value = `${parsedRatio.toFixed(1)}x`;
    color = "green";
  } else if (parsedRatio > 0) {
    const inverseRatio = (1 / parsedRatio).toFixed(1);
    value = `${inverseRatio}x`;
    color = "red";
  } else {
    // Handle zero or invalid ratio
    value = "N/A";
    color = "gray";
  }
  return <span style={{ color }}>{value}</span>;
}; */

export const RouteModal = () => {
  const {
    filteredRoutesResult,
    filteredAirportsResult,
    selectedRoute,
    routeModalOpenState,
    updateRouteModalState,
    filters,
    selectedProfile,
  } = useFilterContext();

  const [modalClass, setModalClass] = useState(
    `${style.modal} ${style.hidden}`
  );
  const [isVisible, setIsVisible] = useState(false);
  const [isTimeOpen, setIsTimeOpen] = useState(false);
  const [isPriceExpanded, setIsPriceExpanded] = useState(false);

  useEffect(() => {
    if (routeModalOpenState) {
      setIsVisible(true);
      setModalClass(`${style.modal} ${style.open}`);
    } else {
      setModalClass(`${style.modal} ${style.close}`);
      setTimeout(() => {
        setModalClass(`${style.modal} ${style.close} ${style.hidden}`);
      }, 300);
    }
  }, [routeModalOpenState]);

  // Close modal if selected route is no longer in filtered results
  useEffect(() => {
    const currentRoute = filteredRoutesResult?.find(
      (r) => r._id === selectedRoute
    );
    if (!currentRoute && routeModalOpenState) {
      updateRouteModalState(false);
    }
  }, [
    filteredRoutesResult,
    selectedRoute,
    routeModalOpenState,
    updateRouteModalState,
  ]);

  // Compute route and airport details
  const route = useMemo(() => {
    return filteredRoutesResult?.find((r) => r._id === selectedRoute);
  }, [filteredRoutesResult, selectedRoute]);

  const airport1Details = useMemo(() => {
    return route
      ? filteredAirportsResult?.find(
          (airport) => airport.airportId === route.airport_1
        )
      : null;
  }, [route, filteredAirportsResult]);

  const airport2Details = useMemo(() => {
    return route
      ? filteredAirportsResult?.find(
          (airport) => airport.airportId === route.airport_2
        )
      : null;
  }, [route, filteredAirportsResult]);

  // Calculate and format flight times
  const { travelTime, flightTime, ratioTime, timeDifference } = useMemo(() => {
    if (!route || !filters) return {};

    let alternativeTravelTimes = [];

    if (route.travel && Array.isArray(filters.travelType)) {
      filters.travelType.forEach((type) => {
        if (typeof route.travel?.[type] === "number") {
          alternativeTravelTimes.push(route.travel[type]);
        }
      });
    }

    const shortestTime =
      alternativeTravelTimes.length > 0
        ? Math.min(...alternativeTravelTimes)
        : undefined;

    // Calculate city center times based on municipality
    let cityCenterTime;
    if (
      airport1Details?.municipality &&
      airport2Details?.municipality &&
      airport1Details.municipality === airport2Details.municipality
    ) {
      // If same municipality, use the shortest time
      cityCenterTime = Math.min(
        airport1Details.timeToCityCenter || 0,
        airport2Details.timeToCityCenter || 0
      );
    } else {
      // If different municipalities, use sum of both times
      cityCenterTime =
        (airport1Details?.timeToCityCenter || 0) +
        (airport2Details?.timeToCityCenter || 0);
    }

    const baseTime =
      cityCenterTime + (filters.departure || 0) + (filters.arrival || 0);

    const travelTime =
      filters.speed && route.distance && filters.speed > 0 && route.distance > 0
        ? Math.ceil((route.distance / filters.speed) * 60)
        : 0;

    const flightTime = baseTime + travelTime;

    /*const ratioTime = shortestTime
      ? (shortestTime / flightTime).toFixed(1)
      : "0.0";*/
    const timeDifference = shortestTime
      ? Math.ceil(shortestTime - flightTime)
      : null;

    return {
      shortestTime,
      baseTime,
      travelTime,
      flightTime,
      /*ratioTime,*/
      timeDifference,
    };
  }, [route, filters, selectedProfile]);

  const formattedFlightTime =
    route && flightTime != null ? formatTime(flightTime) : "N/A";

  const carTimeExists =
    route && filters.travelType?.includes("car") && route.travel?.car > 0;
  const transitTimeExists =
    route &&
    filters.travelType?.includes("transit") &&
    route.travel?.transit > 0;
  const carIsSlower =
    carTimeExists &&
    transitTimeExists &&
    route.travel?.car > route.travel?.transit;
  const transitIsSlower =
    carTimeExists &&
    transitTimeExists &&
    route.travel?.transit > route.travel?.car;

  // Event handlers
  const toggleTimeOpen = () => {
    setIsTimeOpen((prev) => !prev);
  };

  const handleCloseModal = () => {
    updateRouteModalState(false);
  }; // Early return if modal should not be visible
  if (!isVisible) {
    return null;
  }

  if (!route || !airport1Details || !airport2Details) {
    return null;
  }

  // Update modal class when price data expands
  const modalClasses = `${modalClass} ${
    isPriceExpanded ? style.modalExpanded : ""
  }`;

  const getProfileName = () => {
    const names = {
      elflyNoemi: "Elfly Noemi",
      heartES30: "Heart ES-30"
    };
    return selectedProfile !== "default" ? names[selectedProfile] : "";
  };

  // JSX rendering
  return (
    <div className={modalClasses}>
      <div className={style.closeButton}>
        {" "}
        <IconButton
          aria-label="close modal"
          sx={{
            padding: "0.1rem",
          }}
          onClick={handleCloseModal}
        >
          <HighlightOffIcon
            sx={{
              fontSize: "1rem",
              opacity: 0.5,
            }}
          />
        </IconButton>{" "}
      </div>
      <div className={style.wrapper}>
        <div className={style.modalContent}>
          {" "}
          {/* Header section with airport IDs and close button */}
          {/* <div className={rstyle.head}>
        <h3 className={rstyle.airportIdtest}>{airport1Details.airportId}</h3>
        <div className={style.closeButton}>
          <IconButton
            aria-label="close menu"
            size="small"
            onClick={handleCloseModal}
          >
            <CloseIcon style={{ color: "rgba(125, 125, 125, 0.9)" }} />
          </IconButton>
        </div>
      </div> */}
          {/* Airport details and distance */}
          <div className={rstyle.routeModalInfo}>
            <div className={rstyle.airportNameContainer}>
              {airport1Details && airport1Details.name && (
                <>
                  <h4 className={rstyle.airportName}>{airport1Details.name}</h4>
                  <p className={rstyle.routeCountry}>
                    {airport1Details.country.name}
                  </p>
                </>
              )}
            </div>

            <div className={rstyle.routeDistanceWrapper}>
              <p className={rstyle.distance}>{Math.ceil(route.distance)} km</p>
              <p className={rstyle.routeType}>
                {airport1Details.country.name === airport2Details.country.name
                  ? "Domestic"
                  : "International"}
              </p>
            </div>
            <div className={rstyle.airportNameContainer}>
              {airport2Details && airport2Details.name && (
                <>
                  <h4 className={rstyle.airportName}>{airport2Details.name}</h4>
                  <p className={rstyle.routeCountry}>
                    {airport2Details.country.name}
                  </p>
                </>
              )}
            </div>
          </div>
          {/* Route name */}
          <div>
            <p className={rstyle.routeName}>{route.name}</p>
          </div>
          {/* All travel information */}
          <div className={style.modalDetails}>
            {/* Route type indicators (Scheduled/PSO) */}
            <div className={rstyle.routeCommercialPSO}>
              {route.commercial && <p>Scheduled</p>}
              {route.pso && <p>PSO</p>}
              {route.pax > 0 && (
                <p className={rstyle.routeNumberSmall}>
                  {route.pax.toLocaleString()} passengers/year
                </p>
              )}
            </div>
            {/* Flight time information (collapsible) */}
            <div>
              <div
                className={rstyle.collapsibleContainer}
                onClick={toggleTimeOpen}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    toggleTimeOpen();
                  }
                }}
              >
                <div className={rstyle.collapsibleHeader}>
                  <div>
                    {getProfileName() || "Electric Aircraft"}
                  </div>
                  <div className={rstyle.collapsibleHeaderValue}>
                    {formattedFlightTime}
                    <ExpandMoreIcon
                      className={`${rstyle.icon} ${isTimeOpen ? rstyle.iconRotate : ""}`}
                    />
                  </div>
                </div>
              </div>
              <Collapse in={isTimeOpen} timeout={400} unmountOnExit>
                <div className={rstyle.collapsibleContent}>
                  <ul className={rstyle.routeNameList}>
                    <li className={rstyle.flightTimeExpandedValue}>
                      <span>Flight speed</span>{" "}
                      <span>{filters.speed} km/h</span>
                    </li>
                    {airport1Details?.municipality ===
                    airport2Details?.municipality ? (
                      // Special case: same municipality
                      <li className={rstyle.flightTimeExpandedValue}>
                        <span>Drive to airport</span>{" "}
                        <span>
                          {formatTime(
                            Math.min(
                              airport1Details.timeToCityCenter || 0,
                              airport2Details.timeToCityCenter || 0
                            )
                          )}
                        </span>
                      </li>
                    ) : (
                      // Original case: keep exactly as is
                      airport1Details.timeToCityCenter > 0 && (
                        <li className={rstyle.flightTimeExpandedValue}>
                          <span>Drive to airport</span>{" "}
                          <span>
                            {formatTime(airport1Details.timeToCityCenter)}
                          </span>
                        </li>
                      )
                    )}
                    <li className={rstyle.flightTimeExpandedValue}>
                      <span>Airport Time (Departure)</span>{" "}
                      <span>{formatTime(filters.departure)}</span>
                    </li>
                    <li className={rstyle.flightTimeExpandedValue}>
                      <span>Flight</span> <span>{formatTime(travelTime)}</span>
                    </li>
                    <li className={rstyle.flightTimeExpandedValue}>
                      <span>Airport Time (Arrival)</span>{" "}
                      <span>{formatTime(filters.arrival)}</span>
                    </li>
                    {airport1Details?.municipality !==
                      airport2Details?.municipality &&
                      airport2Details.timeToCityCenter > 0 && (
                        <li className={rstyle.flightTimeExpandedValue}>
                          <span>Drive from airport</span>{" "}
                          <span>
                            {formatTime(airport2Details.timeToCityCenter)}
                          </span>
                        </li>
                      )}
                  </ul>
                </div>
              </Collapse>
            </div>
            {/* Additional travel information (car, public transport, etc.) */}
            {carTimeExists && (
              <div className={rstyle.textValueContainer}>
                <div className={rstyle.routeTextStandard}>Car</div>
                <div className={rstyle.routeTextStandard}>
                  {formatTime(route.travel.car, false, carIsSlower)}
                </div>
              </div>
            )}
            {transitTimeExists && (
              <div className={rstyle.textValueContainer}>
                <div className={rstyle.routeTextStandard}>Public Transport</div>
                <div className={rstyle.routeTextStandard}>
                  {formatTime(route.travel.transit, false, transitIsSlower)}
                </div>
              </div>
            )}
            {(carTimeExists || transitTimeExists) && timeDifference != null && (
              <div className={rstyle.timeComparison}>
                <div className={rstyle.textValueContainer}>
                  {/*<div className={rstyle.routeTextStandard}>Ratio</div>
                  <div className={rstyle.routeTextStandard}>
                    {formatRouteSpeedComparison(ratioTime)}
                  </div> */}
                </div>
                <div className={rstyle.textValueContainer}>
                  <div className={rstyle.routeTextStandard}>
                    Time Difference
                  </div>
                  <div className={rstyle.routeTextStandard}>
                    {formatTime(timeDifference, true)}
                  </div>
                </div>
              </div>
            )}
          </div>
          {/*PriceData component */}
          <PriceData
            route={route}
            airport1Details={airport1Details}
            airport2Details={airport2Details}
            onExpandChange={setIsPriceExpanded}
          />
        </div>
      </div>
    </div>
  );
};

import { useState, useCallback, useEffect, useRef } from "react";
import styles from "../css/bottomMapControls.module.css";
import light from "../assets/button/button_lightmode.png";
import dark from "../assets/button/button_darkmode.png";
import google from "../assets/button/button_standard.png";
import satellite from "../assets/button/button_satellite.png";

const MAP_STYLES = {
  SILVER: {
    id: "5a313eaac0c2c586",
    name: "Light",
    colorIndex: 0,
    preview: light,
    type: "styled",
  },
  AUBERGINE: {
    id: "ffe3b9bd26b0c434",
    name: "Dark",
    colorIndex: 1,
    preview: dark,
    type: "styled",
  },
  STANDARD: {
    id: "887f18fceafe25fd",
    name: "Standard",
    colorIndex: 2,
    preview: google,
    type: "styled",
  },
  SATELLITE: {
    id: null,
    name: "Satellite",
    colorIndex: 2,
    preview: satellite,
    type: "satellite",
  },
};

export function MapStyleToggle({ currentStyle, onStyleChange, map }) {
  const [isOpen, setIsOpen] = useState(false);
  const [mapState, setMapState] = useState({
    typeId: map?.getMapTypeId(),
    currentStyle: currentStyle
  });
  const menuRef = useRef(null);

  // Combined map state listener
  useEffect(() => {
    if (!map) return;

    const updateMapState = () => {
      const typeId = map.getMapTypeId();
      const style = typeId === "satellite"
        ? MAP_STYLES.SATELLITE
        : Object.values(MAP_STYLES).find(style => style.id === map.mapId) || MAP_STYLES.SILVER;

      setMapState({
        typeId,
        currentStyle: style
      });
    };

    // Initial state
    updateMapState();

    // Single listener for map changes
    const listener = map.addListener("maptypeid_changed", updateMapState);
    return () => listener.remove();
  }, [map]);

  // Click outside handler
  useEffect(() => {
    if (!isOpen) return;

    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setTimeout(() => {
          setIsOpen(false);
        }, 200);
      }
    };

    const handler = () => {
      document.addEventListener("click", handleClickOutside);
    };

    requestAnimationFrame(handler);
    return () => {
      document.removeEventListener("click", handleClickOutside);
      clearTimeout();
    };
  }, [isOpen]);

  const handleStyleChange = useCallback((key) => {
    const style = MAP_STYLES[key];
    const isSatelliteActive = map?.getMapTypeId() === "satellite";

    if (style.type === "satellite") {
      map?.setMapTypeId(isSatelliteActive ? "roadmap" : "satellite");
    } else {
      if (isSatelliteActive) {
        map?.setMapTypeId("roadmap");
      }
      onStyleChange(key);
    }

    setTimeout(() => {
      setIsOpen(false);
    }, 300);
  }, [map, onStyleChange]);

  return (
    <div
      ref={menuRef}
      className={`${styles.mapStyleToggle} ${
        isOpen ? styles.mapStyleToggleOpen : ""
      }`}
      onClick={() => setIsOpen(!isOpen)}
    >
      {!isOpen && (
        <img
          src={
            mapState.typeId === "satellite"
              ? MAP_STYLES.SATELLITE.preview
              : mapState.currentStyle?.preview || MAP_STYLES.SILVER.preview
          }
          alt={
            mapState.typeId === "satellite"
              ? "Satellite"
              : mapState.currentStyle?.name || "Map Style"
          }
          className={styles.styleIcon}
        />
      )}
      <div className={styles.buttonContainer}>
        {Object.entries(MAP_STYLES).map(([key, style]) => (
          <button
            key={key}
            className={`${styles.styleButton} ${
              (style.type === "satellite" && mapState.typeId === "satellite") ||
              mapState.currentStyle?.id === style.id
                ? styles.active
                : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
              handleStyleChange(key);
            }}
          >
            <img
              src={style.preview}
              alt={style.name}
              className={styles.stylePreview}
            />
            <span className={styles.styleName}>{style.name}</span>
          </button>
        ))}
      </div>
    </div>
  );
}

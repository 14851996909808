import { useState, useLayoutEffect, useRef } from "react";

import style from "../../../css/polylineTooltip.module.css";

export function PolylineTooltip({
  polylineData,
  mousePosition,
  airport1Data,
  airport2Data,
}) {
  const tooltipRef = useRef(null);
  const [tooltipWidth, setTooltipWidth] = useState(0);

  useLayoutEffect(() => {
    if (tooltipRef.current) {
      // Measure the width of the tooltip and update state
      setTooltipWidth(tooltipRef.current.offsetWidth / 2);
    }
  }, [mousePosition, polylineData]);

  const finalStyle = {
    position: "absolute",
    zIndex: 100,
    top: mousePosition.y - 180,
    left: mousePosition.x - tooltipWidth, //subtract half the width of the tooltip element to senter it over the origin point
    filter: "drop-shadow(0.3rem 0.3rem 0.5rem hsla(0, 0%, 13%, 0.7))",
  };

  return (
    <div ref={tooltipRef} style={finalStyle} className={style.scaleUpBottom}>
      <div className={style.tooltip}>
        {" "}
        <div className={style.flexCenter}>
          <p className={style.airportName}>{airport1Data.name}</p>
          <p className={style.country}>{airport1Data.country}</p>
        </div>
        <p className={style.distance}>{Math.ceil(polylineData.distance)} km</p>
        <div className={style.flexCenter}>
          <p className={style.airportName}>{airport2Data.name}</p>
          <p className={style.country}>{airport2Data.country}</p>
        </div>
      </div>
      <div className={style.arrow}></div>
    </div>
  );
}

import { fetchServerData } from "./fetchServerData"; // Import fetch function from getData.js
import { modifyKeys } from "./utilityFunctions";
import { countriesListObj } from "./variables";

// Constants for IndexedDB configuration
const DB_NAME = "Airports_Routes_DB";
const DB_VERSION = 1; // Start with a base version
const TIMESTAMP_STORE = "Timestamp";

export async function fetchAndStoreTimestampAndCountriesList() {
  try {
    let timestamp = await fetchServerData("/neatim");
    timestamp = timestamp[0];
    timestamp.id = timestamp._id;
    delete timestamp._id;

    // Fetch the countries list here, before opening the database
    let countries = modifyKeys(countriesListObj, (key) =>
      key.replace(/_/g, "-")
    );

    // Pass both timestamp and countries list to the database initialization function
    await checkAndInitializeDatabase(timestamp, countries);
    return;
  } catch (error) {
    console.error("Error fetching timestamp or countries:", error);
  }
}

async function checkAndInitializeDatabase(timestamp, countries) {
  let db;
  let upgradeNeeded = false;
  const requiredStores = [TIMESTAMP_STORE, ...Object.keys(countries)];

  // Open the database without specifying a version to check the existing structure
  db = await openDbConnection().catch((error) => {
    console.error("Error opening database:", error);
  });

  // Check for each required store and record if any are missing
  requiredStores.forEach((storeName) => {
    if (!db.objectStoreNames.contains(storeName)) {
      upgradeNeeded = true;
    }
  });

  if (upgradeNeeded) {
    // Close the current connection because we need to upgrade the database
    db.close();

    // Increment the database version to trigger an upgrade
    const newVersion = db.version + 1;
    db = await upgradeDatabase(newVersion, requiredStores).catch((error) => {
      console.error("Error upgrading database:", error);
    });
  }

  // Now that the database is initialized (and potentially upgraded), initialize or update the timestamp and countries list
  updateTimestamp(db, timestamp);

  return db;
}

function upgradeDatabase(newVersion, requiredStores) {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, newVersion);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;

      // Create each missing store
      requiredStores.forEach((storeName) => {
        if (!db.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName, { keyPath: "id" });
        }
      });
    };

    request.onsuccess = (event) => {
      resolve(event.target.result); // Resolve with the upgraded db
    };

    request.onerror = (event) => {
      reject("Error upgrading database:", event.target.error);
    };
  });
}

function updateTimestamp(db, timestamp) {
  const transaction = db.transaction([TIMESTAMP_STORE], "readwrite");
  const store = transaction.objectStore(TIMESTAMP_STORE);

  // transaction.oncomplete = () => {
  //   console.log("Transaction successfully completed.");
  // };

  transaction.onerror = (event) => {
    console.error("Transaction failed:", event.target.error);
  };

  const request = store.put(timestamp);

  // request.onsuccess = () => {
  //   console.log("Timestamp updated successfully");
  // };

  request.onerror = (event) => {
    console.error("Error updating timestamp:", event.target.error);
  };
}

async function openDbConnection() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME);

    request.onsuccess = (event) => {
      resolve(event.target.result); // successfully opened db, resolve with the db object
    };

    request.onerror = (event) => {
      reject("Error opening database:", event.target.error);
    };

    // No 'onupgradeneeded' handler here since we're not initializing or upgrading the DB schema
  });
}

function getMainTimestamp(db) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([TIMESTAMP_STORE], "readonly");
    const store = transaction.objectStore(TIMESTAMP_STORE);
    const request = store.get("timestamp"); // Assuming the main timestamp is stored with the key "timestamp"

    request.onsuccess = () => {
      if (request.result) {
        // Assuming the main timestamp object has 'airports' and 'routes' keys
        resolve(request.result);
      } else {
        reject("Main timestamp not found.");
      }
    };

    request.onerror = (event) => {
      reject("Error fetching main timestamp:", event.target.error);
    };
  });
}

async function updateCountriesIfNeeded(countryNames) {
  const db = await openDbConnection();

  const mainTimestamp = await getMainTimestamp(db);
  let fetchObject = {
    airports: [],
    routes: [],
  };

  for (const countryName of countryNames) {
    const timestamps = await getCountryTimestamps(db, countryName);

    if (timestamps.airports !== mainTimestamp.airports) {
      fetchObject.airports.push(countryName);
    }
    if (timestamps.routes !== mainTimestamp.routes) {
      fetchObject.routes.push(countryName);
      // Check if countryName is already in fetchObject.airports, if not, push it there
      if (!fetchObject.airports.includes(countryName)) {
        fetchObject.airports.push(countryName);
      }
    }
  }

  if (fetchObject.airports.length > 0 || fetchObject.routes.length > 0) {
    // Encode and fetch data for the non-matching countries
    await fetchAndUpdateBatch(db, fetchObject);
  }
}

async function getCountryTimestamps(db, countryName) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([countryName], "readonly");
    const store = transaction.objectStore(countryName);

    const request = store.get(countryName);
    let timestamps = {};

    request.onsuccess = () => {
      timestamps = request.result ? request.result.timestamps : {};
      resolve(timestamps); // Resolve the promise with the timestamps object
    };

    request.onerror = (event) => {
      reject("Error fetching timestamps:", event.target.error);
    };
  });
}

async function fetchAndUpdateBatch(db, fetchObject) {
  let batchData = { airports: [], routes: [] };
  let airportsSkip = 0;
  let routesSkip = 0;
  const limit = 150000; // Number of documents to fetch per request

  while (true) {
    try {
      const airportFetch = {
        countries: fetchObject.airports,
        skip: airportsSkip,
        limit: limit,
      };
      const encodedAirports = encodeURIComponent(JSON.stringify(airportFetch));
      const airportsEndpoint = `/neaair?filter=${encodedAirports}`;
      // Fetch a batch of documents
      // console.log("Sending Airports request", encodedAirports);
      const response = await fetchServerData(airportsEndpoint);

      if (response.length === 0) {
        // Break the loop if no more documents are returned
        break;
      }

      // Accumulate the documents
      batchData.airports = batchData.airports.concat(response);

      // Prepare for the next batch
      airportsSkip += limit;
    } catch (error) {
      console.error("Error fetching data from serverless function:", error);
      return null;
    }
  }

  while (true) {
    try {
      const routesFetch = {
        countries: fetchObject.routes,
        skip: routesSkip,
        limit: limit,
      };
      // console.log(routesFetch.countries);
      const encodedRoutes = encodeURIComponent(JSON.stringify(routesFetch));
      const routesEndpoint = `/nearou?filter=${encodedRoutes}`;
      // Fetch a batch of documents
      // console.log("Sending routes request", encodedRoutes);
      const response = await fetchServerData(routesEndpoint);

      if (response.length === 0) {
        // Break the loop if no more documents are returned
        break;
      }

      // Accumulate the documents
      batchData.routes = batchData.routes.concat(response);

      // Prepare for the next batch
      routesSkip += limit;
    } catch (error) {
      console.error("Error fetching data from serverless function:", error);
      return null;
    }
  }

  try {
    await storeCountriesData(db, fetchObject, batchData);
  } catch (error) {
    console.error("Error fetching or updating batch data:", error);
  }
}

async function storeCountriesData(db, fetchObject, batchData) {
  const fetchedCountries = fetchObject.airports.concat(fetchObject.routes);
  let fetchedCountriesMap = {};

  //used to check if a region code was used in the request
  let regionCodesArray = [];
  fetchedCountries.forEach((country) => {
    if (country.includes("-")) {
      regionCodesArray.push(country);
    }
    fetchedCountriesMap[country] = { airports: [], routes: [] };
  });

  const airportIdandRegionMap = new Map();

  //check regionCodesArray if the airport was fetched using a region code
  batchData.airports.forEach((airport) => {
    if (regionCodesArray.includes(airport.region.iso)) {
      airportIdandRegionMap.set(airport.airportId, airport.region.iso);
      fetchedCountriesMap[airport.region.iso].airports.push(airport);
    } else {
      fetchedCountriesMap[airport.country.iso].airports.push(airport);
    }
  });

  batchData.routes.forEach((route) => {
    if (route.countries.length > 1) {
      route.type = "international";
    } else {
      route.type = "domestic";
    }

    if (airportIdandRegionMap.has(route.airport_1)) {
      const regionCode = airportIdandRegionMap.get(route.airport_1);
      if (fetchedCountriesMap.hasOwnProperty(regionCode)) {
        fetchedCountriesMap[regionCode].routes.push(route);
      }
    }

    if (airportIdandRegionMap.has(route.airport_2)) {
      const regionCode = airportIdandRegionMap.get(route.airport_2);
      if (fetchedCountriesMap.hasOwnProperty(regionCode)) {
        fetchedCountriesMap[regionCode].routes.push(route);
      }
    }

    route.countries.forEach((country) => {
      if (country !== "US" || country !== "CA") {
        if (fetchedCountriesMap.hasOwnProperty(country)) {
          fetchedCountriesMap[country].routes.push(route);
        }
      }
    });
  });

  for (const country of Object.keys(fetchedCountriesMap)) {
    // Store all the airports and routes that involves current country
    const mainTimestamp = await getMainTimestamp(db);
    let countryObject = await getStoredCountryObject(db, country);

    // Ensure the timestamps object exists
    countryObject.timestamps = countryObject.timestamps || {};
    countryObject.id = country;
    if (
      fetchedCountriesMap[country].airports &&
      fetchedCountriesMap[country].airports.length > 0
    ) {
      countryObject.airports = fetchedCountriesMap[country].airports;
      countryObject.timestamps.airports = mainTimestamp.airports;
    }
    if (fetchedCountriesMap[country].routes.length > 0) {
      countryObject.routes = fetchedCountriesMap[country].routes;
      countryObject.timestamps.routes = mainTimestamp.routes;
    }
    await storeCountryObject(db, country, countryObject);
  }
}

async function storeCountryObject(db, country, countryObject) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([country], "readwrite");
    const store = transaction.objectStore(country);

    const request = store.put(countryObject);

    request.onsuccess = async () => {
      countryObject = await request.result;
      resolve("Storing country data success"); // Resolve the promise with the country object
    };

    request.onerror = (event) => {
      reject("Error storing country data:", event.target.error);
    };
  });
}

async function getStoredCountryObject(db, country) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([country], "readonly");
    const store = transaction.objectStore(country);

    const request = store.get(country);

    request.onsuccess = async () => {
      let countryObject = request.result || {
        id: country, // Ensure 'id' matches the keyPath used when creating the store
        airports: [],
        routes: [],
        timestamps: { airports: "", routes: "" },
      };
      resolve(countryObject); // Resolve the promise with the country object
    };

    request.onerror = (event) => {
      reject("Error fetching country data:", event.target.error);
    };
  });
}

async function readCountryData(countryNames) {
  let data = {
    airports: [],
  };

  let routesMap = {};
  const db = await openDbConnection();
  for (const country of countryNames) {
    const countryObject = await getStoredCountryObject(db, country);
    data.airports = data.airports.concat(countryObject.airports);
    countryObject.routes.forEach((route) => {
      routesMap[route._id] = route;
    });
  }
  data.routes = Object.values(routesMap);
  db.close();
  return data;
}

export async function getCountryData(countryNames) {
  await updateCountriesIfNeeded(countryNames);
  const data = await readCountryData(countryNames);
  return data;
}

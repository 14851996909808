export const filtersSchema = {
  type: "object",
  properties: {
    minDistance: {
      type: "number",
      minimum: 0,
      default: 0, // Default value for minDistance
    },

    maxDistance: {
      type: "number",
      minimum: 0,
      default: 200, // Default value for maxDistance
    },

    minRunwayLength: {
      type: "number",
      minimum: 0,
      default: 750, // Default minimum runway length
    },

    maxRunwayLength: {
      type: "number",
      minimum: 0,
      default: 6000, // Adjust the default maximum runway length as needed
    },

    type: {
      type: "array",
      items: {
        type: "string",
        enum: ["large", "medium", "small", "seaplane", "custom"],
      },
      default: ["large", "medium", "seaplane", "small", "custom"], // Default value for type
    },

    country: {
      type: "array",
      items: {
        type: "string",
        pattern: "^[A-Z]{2}(-[A-Z]{2})?$",
      },
      default: ["NO", "SE", "DK", "FI", "IS", "GL", "FO"], // Default value for country
    },

    routeType: {
      type: "array",
      items: {
        type: "string",
        enum: ["domestic", "international", "custom"],
      },
      default: ["domestic", "international", "custom"], // Default value for routeType
    },

    scheduledService: {
      type: "array",
      items: {
        type: "boolean",
      },
      minItems: 0,
      maxItems: 2,
      uniqueItems: true,
      default: [true, false],
    },

    // Objective: Make colors both subtle and fit the NEA color scheme
    colors: {
      type: "object",
      properties: {
        large: {
          type: "array",
          items: {
            type: "string",
            pattern: "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$",
          },
          minItems: 3,
          maxItems: 3,
          default: ["#01baef", "#01baef", "#01baef"],
        },
        medium: {
          type: "array",
          items: {
            type: "string",
            pattern: "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$",
          },
          minItems: 3,
          maxItems: 3,
          default: ["#20BF55", "#20BF55", "#20BF55"], // Green-NEA
        },
        small: {
          type: "array",
          items: {
            type: "string",
            pattern: "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$",
          },
          minItems: 3,
          maxItems: 3,
          default: ["#b78fe0", "#b78fe0", "#b78fe0"],
        },
        seaplane: {
          type: "array",
          items: {
            type: "string",
            pattern: "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$",
          },
          minItems: 3,
          maxItems: 3,
          default: ["#0932fc", "#0932fc", "#0932fc"],
        },
        custom: {
          type: "array",
          items: {
            type: "string",
            pattern: "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$",
          },
          minItems: 3,
          maxItems: 3,
          default: ["#ff6600", "#ff6600", "#ff6600"],
        },
        customRoute: {
          type: "array",
          items: {
            type: "string",
            pattern: "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$",
          },
          minItems: 3,
          maxItems: 3,
          default: ["#CC5200", "#CC5200", "#CC5200"],
        },
        commercialRoute: {
          type: "array",
          items: {
            type: "string",
            pattern: "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$",
          },
          minItems: 3,
          maxItems: 3,
          default: ["#20BF55", "#20BF55", "#001adc"],
        },
        psoRoute: {
          type: "array",
          items: {
            type: "string",
            pattern: "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$",
          },
          minItems: 3,
          maxItems: 3,
          default: ["#9000ff", "#9000ff", "#9000ff"],
        },
        domestic: {
          type: "array",
          items: {
            type: "string",
            pattern: "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$",
          },
          minItems: 3,
          maxItems: 3,
          default: ["#312f31", "#bfbaba", "#312f31"],
        },
        international: {
          type: "array",
          items: {
            type: "string",
            pattern: "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$",
          },
          minItems: 3,
          maxItems: 3,
          default: ["#2b98b7", "#2b98b7", "#01baef"],
        },
      },
      additionalProperties: false,
    },

    currentColorIndex: {
      type: "number",
      default: 0,
      minimum: 0,
      maximum: 2,
    },

    timeSavedActive: {
      type: "boolean",
      default: false,
    },

    timeSavedValue: {
      type: "number",
      minimum: 0,
      maximum: 300,
      default: 60,
    }, // Adjust the default time as needed

    ratioActive: {
      type: "boolean",
      default: false,
    },

    ratioValue: {
      type: "number",
      minimum: 0,
      maximum: 5,
      default: 1,
    }, // Adjust the default ratio as needed

    speed: {
      type: "number",
      minimum: 50,
      maximum: 500,
      default: 250, // Adjust the default speed as needed
    },

    departure: {
      //Time used on airport at departure
      type: "number",
      minimum: 0,
      maximum: 120,
      default: 45, // Adjust the default departure time as needed
    },

    arrival: {
      //Time used on airport at arrival
      type: "number",
      minimum: 0,
      maximum: 120,
      default: 15, // Adjust the default arrival time as needed
    },

    travelType: {
      type: "array",
      items: {
        type: "string",
        enum: ["car", "transit"],
      },
      default: [], // Default value for travelType
    },

    mapCenter: {
      type: "object",
      properties: {
        lat: {
          type: "number",
          minimum: -90, // Minimum latitude value
          maximum: 90, // Maximum latitude value
          default: 68.6546,
        },
        lng: {
          type: "number",
          minimum: -180, // Minimum longitude value
          maximum: 180, // Maximum longitude value
          default: -14.7406,
        },
      },
      required: ["lat", "lng"],
    },

    mapZoom: {
      type: "object",
      properties: {
        zoom: {
          type: "number",
          minimum: 3, // Assuming 0 as the minimum zoom level, adjust as necessary
          maximum: 24, // Assuming 24 as a maximum zoom level used by many mapping services, adjust as necessary
          default: 4,
        },
      },
      required: ["zoom"],
    },

    activeAirport: {
      type: ["object", "null"],
      properties: {
        id: {
          type: "string",
        },
        coordinates: {
          type: "array",
          items: {
            type: "number",
          },
          minItems: 2,
          maxItems: 2,
        },
      },
      additionalProperties: false,
      default: null,
    },

    commercial: {
      // Shows commercial airports only
      type: "boolean",
      default: false,
    },

    pso: {
      // Shows public service obligation airports only
      type: "boolean",
      default: false,
    },

    airportPax: {
      type: "number",
      minimum: 0,
      default: 0,
      multipleOf: 1,
    },

    routesPax: {
      type: "number",
      minimum: 0,
      default: 0,
      multipleOf: 1,
    },

    custom: {
      // Shows custom Airports
      type: "boolean",
      default: true,
    },

    userSizeMultiplier: {
      // multiplier for markers and polylines based on user input
      type: "number",
      default: 1,
    },
  },

  required: [
    "minDistance",
    "maxDistance",
    "minRunwayLength",
    "maxRunwayLength",
    "type",
    "country",
    "routeType",
    "scheduledService",
    "colors",
    "timeSavedActive",
    "timeSavedValue",
    "ratioActive",
    "ratioValue",
    "speed",
    "departure",
    "arrival",
    "travelType",
    "mapCenter",
    "mapZoom",
    "activeAirport",
    "commercial",
    "pso",
    "airportPax",
    "routesPax",
    "custom",
  ],
  additionalProperties: false,
};

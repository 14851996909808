import Popper from "@mui/material/Popper";

export function CustomPopper(props) {
    return (
      <Popper
        {...props}
        style={{ width: "full-width", zIndex: 500000000 }}
        modifiers={[
          {
            name: "matchWidth",
            enabled: true,
            fn: ({ state }) => {
              // Set the popper's width to match the reference element's width
              state.styles.popper.width = `${state.rects.reference.width}px`;
            },
            phase: "beforeWrite",
            requires: ["computeStyles"],
          },
        ]}
      />
    );
  }
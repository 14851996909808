import { useState, useEffect } from "react";
import { useFilterContext } from "./ContextManager";
import ReactApexChart from "react-apexcharts";
import style from "../css/dataView.module.css";

// icons
import chevronLeft from "../assets/svg/chevron_left.svg";
import chevronRight from "../assets/svg/chevron_right.svg";

export function SwitchableChart({ charts }) {
  const { chartIndex, updateChartIndex, showAirports } = useFilterContext();
  // const [chartKey, setChartKey] = useState(showAirports + chartIndex);

  useEffect(() => {
    if (chartIndex >= charts.length) {
      updateChartIndex(0); // Reset chartIndex to 0
    }
  }, [charts, chartIndex, updateChartIndex]);

  const handleChartChange = (step) => {
    if (charts && charts.length > 0) {
      updateChartIndex((prevIndex) => {
        const newIndex = (prevIndex + step + charts.length) % charts.length;
        return newIndex;
      });
    }
    // setChartKey(showAirports + chartIndex);
  };

  // Dynamically compute currentChart based on charts and chartIndex
  const currentChart =
    charts && charts.length > 0 && chartIndex < charts.length
      ? charts[chartIndex]
      : null;

  // If currentChart is not ready, return a fallback
  if (!currentChart) {
    return <div className={style.chartWrapper}>No chart data available.</div>;
  }

  return (
    <div className={style.chartWrapper}>
      <ReactApexChart
        // key={chartKey} // A unique key based on type of table and the chart index, this will ensure a reset of chart settings between switches
        options={currentChart.options}
        series={currentChart.series}
        type={currentChart.type}
        height="100%"
        width="100%"
      />
      <div className={style.chartNavigation}>
        <button
          className={`${style.paginationButton} ${style.buttonEnabled}`}
          onClick={() => handleChartChange(-1)}
          aria-label="Previous Chart"
        >
          <img src={chevronLeft} alt="Previous Chart" />
        </button>
        <p>{currentChart.title}</p>
        <button
          className={`${style.paginationButton} ${style.buttonEnabled}`}
          onClick={() => handleChartChange(1)}
          aria-label="Next Chart"
        >
          <img src={chevronRight} alt="Next Chart" />
        </button>
      </div>
    </div>
  );
}

export const transparentSvg =
  "data:image/svg+xml;charset=UTF-8;base64," +
  btoa(`
<svg xmlns="http://www.w3.org/2000/svg" width="1" height="1">
  <rect width="1" height="1" fill="none" />
</svg>
`);

export const textSvg = (radius) => {
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="120" height="40">
    <style>
        .text-style {
        font-family: 'Arial', sans-serif;
        font-size: 12px;
        font-weight: bold;
        text-shadow: 1px 1px 0px #aaa;
        }
    </style>
    <text x="10" y="20" class="text-style" fill="red">${radius} km</text>
    </svg>`;

  // Properly encode SVG for URL
  const encodedSvg = encodeURIComponent(svg)
    .replace(/'/g, "%27")
    .replace(/"/g, "%22");
  return `data:image/svg+xml;charset=UTF-8,${encodedSvg}`;
};

import { useFilterContext } from "../../ContextManager";

import { mergeFiltersWithDefaults } from "../../../utils/mergeFiltersWithDefaults";
import { delay } from "../../../utils/utilityFunctions";

import Button from "@mui/material/Button";

export function RestoreDefaultValuesButton() {
  const {
    filters,
    updateFilters,
    restoreDefaultCount,
    updateRestoreDefaultCount,
    updateSelectedProfile,
  } = useFilterContext();

  const restoreDefaultSettings = async () => {
    const settingsToKeep = {
      currentColorIndex: filters.currentColorIndex, // Keep current color index, working solution for now
      // country: filters.country,
      // mapCenter: filters.mapCenter,
      // mapZoom: filters.mapZoom,
    };
    const defaultSettings = mergeFiltersWithDefaults(null);
    delay(250);
    const restoredSettings = {
      ...defaultSettings,
      ...settingsToKeep,
    };

    updateFilters(restoredSettings);
    updateSelectedProfile("default"); // Resets to default profile, remove if ever added to filters
    delay(250);
    updateRestoreDefaultCount(restoreDefaultCount + 1);
  };

  return (
    <Button
      variant="contained"
      size="small"
      onClick={restoreDefaultSettings}
      sx={{ fontSize: 11 }}
    >
      Reset Filter
    </Button>
  );
}

import Slider from "@mui/material/Slider";

export function CustomSlider({
  sliderValue,
  updateSliderValue,
  onSliderChangeCommitted,
  min = 0,
  max = 50,
  step = 5,
  filterKeys,
  disabled = false
}) {

  // Update only the local slider value as it moves
  const handleSliderChange = (event, newValue) => {
    updateSliderValue(newValue, filterKeys); // Update local state
  };

  // Update the global filters state when the user stops moving the slider
  const handleSliderChangeCommitted = (event, newValue) => {
    onSliderChangeCommitted(newValue, filterKeys); // Update filters state in parent
  };

  return (
    <Slider
      value={sliderValue}
      onChange={handleSliderChange}
      onChangeCommitted={handleSliderChangeCommitted}
      valueLabelDisplay="auto"
      step={step}
      color="primary"
      min={min}
      max={max}
      disabled={disabled} // Adjust the max value as needed
    />
  );
}

import originalGeoJsonData from "../data/geojson/worldGeoJSON.json";

export const mainColorDark = "rgb(60, 60, 60)";
export const mainColorLight = "rgb(88, 88, 90)";

export const countriesListObj = {
  PL: "Poland",
  DK: "Denmark",
  GL: "Greenland",
  LT: "Lithuania",
  IS: "Iceland",
  FI: "Finland",
  NO: "Norway",
  EE: "Estonia",
  SE: "Sweden",
  FO: "Faroe Islands",
  LV: "Latvia",
};

export function handleGeoJSON() {
  let data = originalGeoJsonData;

  data.features = data.features
    .filter((feature) => {
      let modifiedIsoString = feature.properties.iso.replace(/-/g, "_");
      return countriesListObj.hasOwnProperty(modifiedIsoString);
    })
    .map((feature) => {
      let modifiedIsoString = feature.properties.iso.replace(/-/g, "_");
      feature.properties.name = countriesListObj[modifiedIsoString];
      return feature;
    });

  return data;
}

export const isoLatLong = {
  PL: "52.0,20.0",
  DK: "56.0,10.0",
  GL: "72.0,-40.0",
  LT: "56.0,24.0",
  IS: "65.0,-18.0",
  FI: "64.0,26.0",
  NO: "62.0,10.0",
  EE: "59.0,26.0",
  SE: "62.0,15.0",
  FO: "62.0,-7.0",
  LV: "57.0,25.0",
};

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";

import { FilterContextProvider } from "./components/ContextManager";

//Clerk
import { ClerkProvider } from "@clerk/clerk-react";
import { dark } from "@clerk/themes";
const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

import { ThemeProvider } from "@mui/material/styles";
import muiTheme from "./theme/muiTheme";

ReactDOM.createRoot(document.getElementById("root")).render(
  <FilterContextProvider>
    <ThemeProvider theme={muiTheme}>
      <ClerkProvider
        // appearance={{
        //   baseTheme: dark,
        // }}
        publishableKey={PUBLISHABLE_KEY}
        afterSignOutUrl="/"
      >
        <App />
      </ClerkProvider>
    </ThemeProvider>
  </FilterContextProvider>
);

import { useEffect, useRef, useState } from "react";
import { useFilterContext } from "../../ContextManager";
import style from "../../../css/searchBar.module.css";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";

export function SearchBar({ map }) {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");

  const { searchBarIsOpen, updateSearchBarIsOpen } = useFilterContext();

  const autocompleteServiceRef = useRef(null);
  const placesServiceRef = useRef(null);
  const autocompleteRef = useRef(null);

  useEffect(() => {
    if (!map) return;

    let autocomplete;
    const inputElement = inputRef.current;

    if (searchBarIsOpen) {
      // Initialize autocomplete when the search bar is open
      autocomplete = new google.maps.places.Autocomplete(inputElement, {
        fields: ["geometry", "name"],
      });
      autocomplete.bindTo("bounds", map);

      autocompleteRef.current = autocomplete;
      autocompleteServiceRef.current =
        new google.maps.places.AutocompleteService();
      placesServiceRef.current = new google.maps.places.PlacesService(map);

      // Restore the handleKeyDown event listener
      const handleKeyDown = (event) => {
        if (event.key !== "Enter") {
          return;
        }

        if (document.querySelector(".pac-item-selected")) {
          // If a suggestion is already selected, do nothing
          return;
        }

        // Simulate ArrowDown key to select the first autocomplete suggestion
        inputElement.dispatchEvent(
          new KeyboardEvent("keydown", {
            key: "ArrowDown",
            code: "ArrowDown",
            keyCode: 40,
            bubbles: true,
            cancelable: true,
          })
        );
      };
      inputElement.addEventListener("keydown", handleKeyDown);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry || !place.geometry.location) {
          console.error("Returned place contains no geometry");
          return;
        }

        map.setCenter(place.geometry.location);
        map.setZoom(9); // Set zoom level to 9

        setInputValue("");
        inputElement.blur();
        updateSearchBarIsOpen(false);
      });

      return () => {
        // Clean up autocomplete when the search bar is closed
        google.maps.event.clearInstanceListeners(autocomplete);
        inputElement.removeEventListener("keydown", handleKeyDown);
        autocomplete = null;
        autocompleteRef.current = null;
      };
    } else {
      // When the search bar is closed, remove autocomplete instance
      if (autocompleteRef.current) {
        google.maps.event.clearInstanceListeners(autocompleteRef.current);
        autocompleteRef.current = null;
      }
    }
  }, [map, searchBarIsOpen]);

  const handleClick = () => {
    if (searchBarIsOpen === false) {
      updateSearchBarIsOpen(true);
    } else {
      if (inputValue.trim() === "") {
        // Do nothing if input is empty
        return;
      }

      const autocompleteService = autocompleteServiceRef.current;
      const placesService = placesServiceRef.current;

      if (autocompleteService && placesService) {
        const options = {
          input: inputValue,
          locationBias: map.getBounds(),
        };

        autocompleteService.getPlacePredictions(
          options,
          (predictions, status) => {
            if (
              status !== google.maps.places.PlacesServiceStatus.OK ||
              !predictions ||
              predictions.length === 0
            ) {
              console.error("No predictions available");
              return;
            }

            const firstPrediction = predictions[0];
            const placeId = firstPrediction.place_id;

            // Now get the place details
            placesService.getDetails(
              { placeId: placeId, fields: ["geometry", "name"] },
              (place, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                  if (place.geometry && place.geometry.location) {
                    map.setCenter(place.geometry.location);
                    map.setZoom(9); // Set zoom level to 9

                    setInputValue("");
                    inputRef.current.blur();
                    updateSearchBarIsOpen(false);
                  }
                } else {
                  console.error("Failed to get place details:", status);
                }
              }
            );
          }
        );
      }
    }
  };

  useEffect(() => {
    if (searchBarIsOpen === false) {
      setInputValue("");
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    }
  }, [searchBarIsOpen]);

  return (
    <div
      className={
        searchBarIsOpen === true
          ? `${style.searchBar} ${style.searchBarOpen}`
          : style.searchBar
      }
    >
      <input
        className={
          searchBarIsOpen === true
            ? style.searchBarInput
            : `${style.searchBarInput} ${style.hidden}`
        }
        ref={inputRef}
        type="text"
        placeholder="Search places..."
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onFocus={() => {
          // Immediately clear the input value
          inputRef.current.value = "";
          setInputValue("");
        }}
      />
      <SearchSharpIcon
        className={style.searchIcon}
        fontSize="medium"
        onClick={handleClick}
      />
    </div>
  );
}

import { useState, useEffect } from "react";
import { useFilterContext } from "./ContextManager";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import style from "../css/modal.module.css";
import { delay } from "../utils/utilityFunctions";

export const Modal = () => {
  const {
    updateFilters,
    filteredAirportsResult,
    selectedAirport,
    modalOpenState,
    updateModalState,
  } = useFilterContext();

  const [hasOpened, setHasOpened] = useState(false);
  const [modalClass, setModalClass] = useState(
    `${style.modal} ${style.hidden}`
  );
  const [lastSelectedAirport, setLastSelectedAirport] = useState(null);
  const [airport, setAirport] = useState(null);

  useEffect(() => {
    if (selectedAirport) {
      setLastSelectedAirport(selectedAirport);
    }
    updateFilters((prevFilters) => ({
      ...prevFilters,
      activeAirport: selectedAirport,
    }));
  }, [selectedAirport]);

  useEffect(() => {
    if (selectedAirport) {
      const openModal = async () => {
        await delay(500);
        updateModalState(true);
        setHasOpened(true);
      };
      openModal();
    }
  }, []);

  useEffect(() => {
    if (modalOpenState) {
      setModalClass(`${style.modal} ${style.open}`);
      setHasOpened(true);
    } else if (hasOpened) {
      setModalClass(`${style.modal} ${style.close}`);
      setTimeout(() => {
        setModalClass(`${style.modal} ${style.close} ${style.hidden}`);
      }, 300);
    }
  }, [modalOpenState]);

  useEffect(() => {
    if (lastSelectedAirport) {
      setAirport(
        filteredAirportsResult &&
          filteredAirportsResult.find(
            (airport) => airport.airportId === lastSelectedAirport.id
          )
      );
    } else {
      setAirport(null);
    }
  }, [lastSelectedAirport]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (modalOpenState && !airport) {
        updateModalState(false);
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [modalOpenState, airport, updateModalState]);

  let array = [];
  if (airport) {
    if (airport.municipality) array.push(airport.municipality);
    if (airport.country.name) array.push(airport.country.name);
  }
  const airportLocation = array.join(", ");

  return (
    <div className={modalClass}>
      <div className={style.closeButton}>
        {" "}
        <IconButton
          aria-label="close modal"
          sx={{
            padding: "0.1rem",
          }}
          onClick={() => updateModalState(false)}
        >
          <HighlightOffIcon
            sx={{
              fontSize: "1rem",
              opacity: 0.5,
            }}
          />
        </IconButton>{" "}
      </div>
      <div className={style.wrapper}>
        <div className={style.modalContent}>
          <div className={style.airportNameContainer}>
            {airport && airport.name && (
              <h4 className={style.airportName}>{airport.name}</h4>
            )}
          </div>
          <div className={style.modalInfo}>
            {airport && (
              <>
                <div>
                  {" "}
                  {airport.type !== "custom" &&
                    (airport.country.name || airport.municipality) && (
                      <div className={style.airportStats}>
                        {(airport.airportId || airport.iataCode) && (
                          <p className={style.statsValue}>
                            {airport.airportId || ""}
                            {airport.iataCode
                              ? `${airport.airportId ? " | " : ""}${airport.iataCode}`
                              : ""}
                          </p>
                        )}
                        <p className={style.statsValue}>{airportLocation}</p>
                      </div>
                    )}
                  {airport.type && airport.type === "seaplane" && (
                    <div className={style.airportStats}>
                      <p className={style.statsValue}>{airport.type} Base</p>
                    </div>
                  )}
                  {airport.type && airport.type !== "seaplane" && (
                    <div className={style.airportStats}>
                      <p className={style.statsValue}>{airport.type} Airport</p>
                    </div>
                  )}
                  {airport && airport.pax && (
                    <div className={`${style.airportStats} ${style.statsItem}`}>
                      <p className={style.statsValue}>
                        {airport.pax.toLocaleString()} passengers/year
                      </p>
                    </div>
                  )}
                </div>
                {airport.runways && airport.runways.length > 0 && (
                  <div>
                    <h4 className={style.runwaysTitle}>Runways</h4>
                    <ul className={style.runwaysList}>
                      {airport.runways.map((runway, i) => (
                        <li
                          key={i}
                          className={`${style.airportStats} ${style.runwayListItem}`}
                        >
                          <span className={style.statsRunway}>
                            {runway.length_m} m
                          </span>
                          <span className={style.statsValue}>
                            {" "}
                            - {runway.surface.replace("_", " ")}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {(airport.homepage || airport.wiki) && (
                  <div className={style.linkButtonContainer}>
                    {airport.homepage && (
                      <div className={style.airportStats}>
                        <Button
                          variant="contained"
                          href={airport.homepage}
                          target="_blank"
                          size="small"
                          sx={{
                            fontSize: "11px",
                            fontWeight: 450,
                          }}
                        >
                          Homepage
                        </Button>
                      </div>
                    )}

                    {airport.wiki && (
                      <div className={style.airportStats}>
                        <Button
                          variant="contained"
                          href={airport.wiki}
                          target="_blank"
                          size="small"
                          sx={{
                            fontSize: "11px",
                            fontWeight: 450,
                          }}
                        >
                          Wikipedia
                        </Button>
                      </div>
                    )}
                  </div>
                )}
                {airport && airport.description && (
                  <div className={style.description}>{airport.description}</div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

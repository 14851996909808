import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import style from "../../../css/bottomMapControls.module.css";

export function CustomZoomControls({ map }) {
  const handleZoomIn = () => {
    if (map) {
      map.setZoom(map.getZoom() + 0.5);
    }
  };

  const handleZoomOut = () => {
    if (map) {
      map.setZoom(map.getZoom() - 0.5);
    }
  };

  return (
    <div className={style.customZoomControls}>
      <IconButton
        onClick={handleZoomIn}
        className={style.zoomButton}
        sx={{
          backgroundColor: "rgba(250, 250, 250, 0.4)",
          "&:hover": {
            backgroundColor: "rgba(250, 250, 250, 0.9)",
            outline: "2px solid rgba(51, 51, 51, 0.75)",
          },
        }}
      >
        <AddIcon
          sx={{
            fill: "#666666",
            "&:hover": {
              fill: "#333333",
            },
          }}
        />
      </IconButton>

      <IconButton
        onClick={handleZoomOut}
        className={style.zoomButton}
        sx={{
          backgroundColor: "rgba(250, 250, 250, 0.4)",
          "&:hover": {
            backgroundColor: "rgba(250, 250, 250, 0.9)",
            outline: "2px solid rgba(51, 51, 51, 0.75)",
          },
        }}
      >
        <RemoveIcon
          sx={{
            fill: "#666666",
            "&:hover": {
              fill: "#333333",
            },
          }}
        />
      </IconButton>
    </div>
  );
}

import { useEffect } from "react";
import { useFilterContext } from "./components/ContextManager";

import { Layout } from "./components/Layout";

import { mergeFiltersWithDefaults } from "./utils/mergeFiltersWithDefaults";
import {
  decompressObjectFromURL,
  compressObjectForURL,
} from "./data/schemas/filterCodes";

function App() {
  const { filters, updateFilters, onSelectAirport } = useFilterContext();

  useEffect(() => {
    updateFilters(() => {
      const queryParams = new URLSearchParams(window.location.search);
      const urlFilters = queryParams.get("filters");
      const savedFilters = localStorage.getItem("filters") || "{}";
      let finalFilters = "{}";

      if (urlFilters) {
        finalFilters = mergeFiltersWithDefaults(
          decompressObjectFromURL(urlFilters)
        );
      } else {
        finalFilters = mergeFiltersWithDefaults(savedFilters);
      }
      if ("activeAirport" in finalFilters) {
        onSelectAirport(finalFilters.activeAirport);
      }

      return finalFilters;
    });
  }, []);

  // This useEffect hook is triggered whenever the 'filters' state changes.
  useEffect(() => {
    // Serialize the 'filters' object into a JSON string and encode it for URL compatibility.
    const compressedFilters = compressObjectForURL(filters);

    // Construct a new URL with the serialized filters as a query parameter.
    const newUrl = `${window.location.pathname}?filters=${compressedFilters}`;

    // Update the browser's URL without reloading the page.
    window.history.pushState({}, "", newUrl);

    // Also save the serialized filters in the browser's localStorage.
    localStorage.setItem("filters", JSON.stringify(filters));
  }, [filters]); // Dependency array: this effect runs when 'filters' state changes.

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    // Attach the event listener to the document
    document.addEventListener("contextmenu", handleContextMenu);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);
  return (
    <div className="wrapper">
      <Layout />
    </div>
  );
}

export default App;
